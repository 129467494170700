const Tvbet = () => {
  const fullScreen = `
    "position": "fixed", 
                    "top": "0",
                    "left": "0",
                    "bottom": "0", 
                    "right": "0", 
                    "width": "100%", 
                    "height": "100%", 
                    "border": "none", 
                    "margin": "0", 
                    "padding": "0", 
                    "overflow": "hidden", 
                    "zIndex": "999999",`;
  return (
    <div>
      <iframe
        src="https://tvbetframe.com/?lng=en&tokenAuth=5ca2c0c29951ec787f9b28c2e73b1a52b92ac62c082e3116a1d4492009ab54cb&clientId=6825#/lobby"
        style={{
          height: "calc(100vh - 4px)",
          width: "calc(100vw - 4px)",
          "box-sizing": "border-box",
        }}
      >
        Your browser doesn't support iframes
      </iframe>
    </div>
  );
};

export default Tvbet;
