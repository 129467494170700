import "./register.css";
import SignPromocodeIcon from "../../assets/icons/sign_promocode_icone.svg";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";

const Register = () => {
  const { user, registerUser, isAuthenticated } = useContext(AuthContext);

  const [passwordShown, setPasswordShown] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: "First Name",
    lastName: "Last Name",
    dateOfBirth: "01/01/1980",
    email: "",
    country: "Cyprus",
    countryCode: "+357",
    city: "Nicosia",
    address: "Nicosia",
    mobileNumber: "357112233",
    userName: "",
    currency: "EUR",
    password: "",
    passwordVerify: "",
    receive_all_promos: true,
    agreeTermsConditions: false,
    zipCode: "0000",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Checking input data is empty or not
    if (
      !formValue.firstName ||
      !formValue.lastName ||
      !formValue.dateOfBirth ||
      !formValue.email ||
      !formValue.country ||
      !formValue.countryCode ||
      !formValue.city ||
      !formValue.address ||
      !formValue.mobileNumber ||
      !formValue.userName ||
      !formValue.currency ||
      !formValue.password ||
      !formValue.passwordVerify ||
      !formValue.receiveAllPromos ||
      !formValue.agreeTermsConditions
    ) {
      console.log("Please add all required fields");
    }

    // Checking password is matching or not
    if (formValue.password !== formValue.passwordVerify) {
      //toast.error("Password does not match.");
      return;
    }

    registerUser(formValue);
  };

  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    city,
    address,
    mobileNumber,
    userName,
    password,
    passwordVerify,
    currency,
    country,
    countryCode,
    receiveAllPromos,
    agreeTermsConditions,
    promoCode,
  } = formValue;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    console.log("Register useEffect: ");
  }, [isAuthenticated]);

  return (
    <main className="page page-wrapper">
      <div className="sign_page">
        <div className="sign_page__container">
          <div className="sign_page__wrapper">
            <div className="sign_top">
              <div className="sign_title">Sign up</div>
              <div className="sign_text">
                We need you to help us with some basic information to create
                your account.
              </div>
            </div>
            <form onSubmit={handleSubmit} className="sign_form" autoComplete='off'>

            {/* Email */}
            <label htmlFor="">
                Email
                <input 
                  name="email"
                  type="text" 
                  placeholder="Email" 
                  autoComplete="off"
                  id="email"
                  required
                  value={email}
                  onChange={handleChange}
                  />
              </label>

              {/* Username */}
              <label htmlFor="">
                Username
                <input 
                  name="userName"
                  type="text" 
                  placeholder="Username" 
                  autoComplete='off'
                  id="userName"
                  required
                  value={userName}
                  onChange={handleChange}
                  />
              </label>

              

              <label htmlFor="">
                Password
                <input 
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password" 
                  autoComplete='off'
                  id="password"
                  required
                  value={password}
                  onChange={handleChange}
                  />
              </label>

              <label htmlFor="">
                Confirm password
                <input 
                  name="passwordVerify"
                  type={passwordShown ? "text" : "password"}
                  placeholder="Confirm password" 
                  autoComplete='off'
                  id="passwordVerify"
                  required
                  value={passwordVerify}
                  onChange={handleChange}
                  />
              </label>

              <div className="sign_pomocod">
                Promocode{" "}
                <div className="sign_pomocod__btn">
                  <div>
                    <img src={SignPromocodeIcon} alt="" aria-hidden="true" />
                  </div>
                </div>
              </div>

              <label htmlFor="" className="sing_input_promo">
                <input 
                  name="promoCode"
                  type="text" 
                  placeholder="Promocode"
                  autoComplete='off'
                  id="promoCode"
                  value={promoCode}
                  onChange={handleChange}
                  />
              </label>

              <img src="./img/login_fer.jpg" alt="" />
              <label htmlFor="" className="sign_checbox">
                <input 
                  type="checkbox" 
                  name="agreeTermsConditions"
                  id="agreeTermsConditions"
                  value={agreeTermsConditions}
                  onChange={handleChange} 
                  required
                  checked 
                  />
                <span>
                  I agree to all the{" "}
                  <Link to="/terms-and-conditions" target="_blank">
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy policy{" "}
                  </Link>
                </span>
              </label>

              <label htmlFor="" className="sign_checbox">
                <input type="checkbox" name="confirm" required checked /> I confirm that i am 18
                years old or over
              </label>

              <button type="submit" className="btn-green sign_submit">
                Register
              </button>

              <div className="sign_descr">
                By clicking the button, I accept the{" "}
                <Link to="/terms-and-conditions" target="_blank">
                  Terms of Use of the service
                </Link>{" "}
                and its{" "}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                , as well as consent to the processing of personal data
              </div>

              <div className="login_other">
                <span>or</span>
                <div className="login_other__items">
                  <Link href="#" className="login_btn_other">
                    Continue with facebook
                  </Link>
                  <Link href="#" className="login_btn_other">
                    Continue with Google
                  </Link>
                </div>
              </div>

              <div className="login_create">
                Already have an account? <Link to="/login">Log in</Link>
              </div>

            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
