import "./casino.css";

import CasinoHero from "./CasinoHero";
import Slots from "./Slots";
import AcceptCrypto from "./AcceptCrypto";

const Casino = () => {
  return (
    <main className="page page-wrapper">
      <CasinoHero />
      <Slots />
      <AcceptCrypto />
    </main>
  );
};

export default Casino;
