// For Development set
// 'domain': 'http://localhost:5000',
// 'production': 'false'

// For Production set
// 'domain': 'https://crossgaming.me',
// 'production': 'true'

export const settings = {
    'domain': 'https://crossgaming.me',
    'production': 'true'
}

// Checking production/development status
export const domain = () => {
    let domain = settings.domain;
    
    if (settings.production === 'true') {
        domain = settings.domain + '/api/'
    }

    return domain;
};