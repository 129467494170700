import "./profile.css";
import { useContext, useEffect } from "react";

import AuthContext from "../../context/AuthContext";

const Profile = () => {
  const { user, isAuthenticated, logoutUser } = useContext(AuthContext);

  useEffect( () => {
    console.log(user);
  }, [user]);
  

  return (
    <main className="page page-wrapper">
      <section className="profile reset_commission">
        <div className="profile__container">
          <div className="profile__wrapper ">
            <h1 className="profile_title user_page__title">Profile settings</h1>

            <div className="profile_bottom">
              <form action="#" className="profile_form">
                <div className="profile_upload"></div>
                <label htmlFor="#">
                  {" "}
                  First Name
                  <input 
                    type="text" 
                    placeholder="First Name"  
                    value={user == null ? "First Name" : user.firstName}
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  Last Name
                  <input 
                    type="text" 
                    placeholder="Last Name"  
                    value={user == null ? "Last Name" : user.lastName}
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  E-mail Address
                  <input 
                    type="email" 
                    placeholder="E-mail Address"  
                    value={user == null ? "E-mail" : user.email}
                    />
                </label>
                <label htmlFor="#">
                  {" "}
                  Mobile Number
                  <input
                    id="phone"
                    type="text"
                    placeholder="Mobile Number"
                    value={user == null ? "Mobile Number" : user.mobileNumber}
                    
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  Username
                  <input 
                    type="text" 
                    placeholder="Username"  
                    value={user == null ? "Username" : user.userName}  
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  Country
                  <input
                    id="country"
                    type="text"
                    placeholder="Country"
                    value={user == null ? "Country" : user.country}
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  Address
                  <input 
                    type="text" 
                    placeholder="Address"
                    value={user == null ? "Address" : user.address}  
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  State
                  <input 
                    type="text" 
                    placeholder="State"  
                    value={user == null ? "Address" : user.state}
                  />
                </label>
                <label htmlFor="#">
                  {" "}
                  Zip Code
                  <input type="text" placeholder="Zip Code"  />
                </label>
                <label htmlFor="#">
                  {" "}
                  City
                  <input 
                    type="text" 
                    placeholder="City"  
                    value={user == null ? "City" : user.city}
                  />
                </label>
                <button className="btn btn-green btn_profile">
                  Update Profile
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Profile;
