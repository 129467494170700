import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    let navigate = useNavigate();

    return (
    <main className="page page-wrapper">
      <section className="login_page">
        <div className="login_page__container">
          <div className="login_page__wrapper">
            <div className="login_top">
              <div className="login_title">Oops! Page Not Found</div>
              <div className="login_text" style={{ fontSize: "14px" }}>
                We're sorry, we could not find the page you are looking for.
              </div>
              {/*<img src={LoginFerIcon} alt="Click to verify" />*/}
              <button 
                type="button" 
                className="btn btn-green btn_login"
                onClick={ () => navigate(-1)}
                >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default PageNotFound;
