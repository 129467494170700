

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/));

const AcceptCrypto = () => {
  return (
    <section className="accept">
      <div className="accept__container">
        <div className="accept_title title_h2">
          We Accept All Ppopular Crypto
        </div>
        <div className="accept_content">
          In the gaming hall, you have access to slot machines, with the highest
          return percentage and incredibly gambling gameplay!
        </div>
        <div className="accept_items dis-f jcc aic">
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_1.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              Tether <br />
              (USDT)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_2.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              Bitcoin <br />
              (BTC)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_3.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              Ethereum <br />
              (ETH)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_4.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              Litecoin <br />
              (LTC)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_5.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              TRON <br />
              (TRX)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_6.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              Binance Coin <br /> (BNB)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_7.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              Binance USD <br /> (BUSD)
            </div>
          </div>
          <div className="accept_item">
            <img
              loading="lazy"
              src={images['crypto_8.png']}
              alt="Tether"
            />
            <div className="accept_item__title">
              USD Coin <br />
              (USDC)
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AcceptCrypto;
