import { Link } from "react-router-dom";
import CasinoHeroImage from '../../assets/casino/casino_hero_img.png';
import CardsImage from '../../assets/icons/cards.png';


const CasinoHero = () => {
  return (
    <section className="casino_hero">
      <div className="casino_hero__container">

        <div className="casino_hero__wrapper">
          <div className="casino_hero__inner">
            <img src={CasinoHeroImage} alt="Casino" />
            <div className="casino_hero__content">
              <h1 className="casino_title title_h1">
                <img
                  loading="lazy"
                  src={CardsImage}
                  alt="Casino"
                />
                Casino
              </h1>
              <div className="casino_hero__descr">
                Dive in to our wide range of in-house games, live casino and
                slots to experience a thrilling casino adventure.
              </div>

              <div className="hero_bottom__btns dis-f jcsb aic">
                <Link
                  to="/casino"
                  className="hero_bottom__btn btn-blue btn"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.80371 14.8066C8.66679 14.8066 8.53803 14.86 8.4412 14.9568C8.34437 15.0537 8.29102 15.1824 8.29102 15.3193C8.29102 15.4563 8.34437 15.585 8.4412 15.6819C8.53803 15.7787 8.66679 15.8321 8.80371 15.8321C8.94067 15.8321 9.06939 15.7787 9.16625 15.6819C9.36617 15.482 9.36617 15.1567 9.16625 14.9568C9.06939 14.86 8.94067 14.8066 8.80371 14.8066Z"
                      fill="white"
                    ></path>
                    <path
                      d="M11.1963 7.76562H2.78809C1.93999 7.76562 1.25 8.45561 1.25 9.30371V17.7119C1.25 18.56 1.93999 19.25 2.78809 19.25H11.1963C12.0444 19.25 12.7344 18.56 12.7344 17.7119V9.30371C12.7344 8.45561 12.0444 7.76562 11.1963 7.76562ZM6.26823 12.7839C5.9777 13.0744 5.59147 13.2344 5.18063 13.2344C4.76979 13.2344 4.38356 13.0743 4.09303 12.7839C3.49335 12.1841 3.49335 11.2084 4.09303 10.6087C4.38353 10.3182 4.76976 10.1581 5.1806 10.1581C5.59144 10.1581 5.97767 10.3182 6.26819 10.6087C6.86794 11.2084 6.86794 12.1841 6.26823 12.7839ZM9.89131 16.4069C9.60078 16.6974 9.21455 16.8575 8.80371 16.8575C8.39287 16.8575 8.00664 16.6974 7.71611 16.4069C7.11643 15.8072 7.11643 14.8315 7.71611 14.2317C8.00664 13.9413 8.39287 13.7812 8.80371 13.7812C9.21455 13.7812 9.60078 13.9412 9.89131 14.2318C10.491 14.8315 10.491 15.8072 9.89131 16.4069Z"
                      fill="white"
                    ></path>
                    <path
                      d="M5.18042 11.1835C5.04349 11.1835 4.91474 11.2369 4.81791 11.3337C4.61799 11.5336 4.61799 11.8589 4.81791 12.0588C4.91474 12.1556 5.04349 12.209 5.18042 12.209C5.31737 12.209 5.44609 12.1556 5.54296 12.0588C5.63976 11.9619 5.69311 11.8332 5.69311 11.6963C5.69311 11.5593 5.63976 11.4305 5.54293 11.3337C5.44609 11.2369 5.31734 11.1835 5.18042 11.1835Z"
                      fill="white"
                    ></path>
                    <path
                      d="M15.1609 4.82617C15.024 4.82617 14.8952 4.87953 14.7984 4.97636C14.5985 5.17627 14.5985 5.50153 14.7984 5.70145C14.8952 5.79828 15.024 5.85163 15.1609 5.85163C15.2978 5.85163 15.4266 5.79828 15.5234 5.70145C15.6202 5.60458 15.6736 5.47583 15.6736 5.33887C15.6736 5.20194 15.6202 5.07319 15.5234 4.97636C15.4266 4.87953 15.2978 4.82617 15.1609 4.82617Z"
                      fill="white"
                    ></path>
                    <path
                      d="M11.217 5.70141C11.417 5.50149 11.417 5.17624 11.217 4.97632C11.1202 4.87953 10.9915 4.82617 10.8545 4.82617C10.7176 4.82617 10.5888 4.87953 10.492 4.97636C10.3952 5.07319 10.3418 5.20194 10.3418 5.33887C10.3418 5.47583 10.3952 5.60458 10.492 5.70141C10.5888 5.79824 10.7176 5.8516 10.8545 5.8516C10.9915 5.8516 11.1202 5.79824 11.217 5.70141Z"
                      fill="white"
                    ></path>
                    <path
                      d="M15.5234 10.008C15.6203 9.91116 15.6736 9.78241 15.6736 9.64548C15.6736 9.50853 15.6203 9.37977 15.5234 9.28294C15.3235 9.08309 14.9983 9.08309 14.7984 9.28294C14.5985 9.48286 14.5985 9.80811 14.7984 10.008C14.8952 10.1049 15.024 10.1582 15.1609 10.1582C15.2978 10.1582 15.4266 10.1048 15.5234 10.008Z"
                      fill="white"
                    ></path>
                    <path
                      d="M17.2119 1.75H8.80371C7.95561 1.75 7.26562 2.43999 7.26562 3.28809V6.74023H10.2195C10.0532 6.66477 9.89992 6.55949 9.76689 6.42647C9.16721 5.82675 9.16721 4.85099 9.76689 4.25127C10.0574 3.96081 10.4437 3.80078 10.8545 3.80078C11.2653 3.80078 11.6516 3.96078 11.9421 4.2513C12.5418 4.85102 12.5418 5.82678 11.9421 6.4265C11.801 6.56752 11.6373 6.67752 11.4592 6.75367C12.6638 6.87695 13.6241 7.83774 13.7465 9.04258C13.8214 8.86642 13.9301 8.70133 14.0735 8.55791C14.6732 7.95823 15.649 7.95826 16.2487 8.55788C16.8484 9.15763 16.8484 10.1334 16.2487 10.7331C15.9582 11.0236 15.5719 11.1836 15.1611 11.1836C14.7503 11.1836 14.364 11.0236 14.0735 10.7331C13.9384 10.598 13.834 10.4436 13.7597 10.2789V13.2344H17.2119C18.06 13.2344 18.75 12.5444 18.75 11.6963V3.28809C18.75 2.43999 18.06 1.75 17.2119 1.75ZM16.2487 6.42647C15.9582 6.71696 15.5719 6.87699 15.1611 6.87699C14.7503 6.87699 14.364 6.71696 14.0735 6.42647C13.4738 5.82675 13.4738 4.85099 14.0735 4.25127C14.364 3.96081 14.7503 3.80078 15.1611 3.80078C15.572 3.80078 15.9582 3.96078 16.2487 4.2513C16.8484 4.85102 16.8484 5.82675 16.2487 6.42647Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span>Casino</span>
                </Link>

                <Link
                  to="/live-casino"
                  className="hero_bottom__btn btn-pink  btn"
                >
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_372_3430)">
                      <path
                        d="M11.0127 1.75V3.86094C12.4649 3.97233 13.7868 4.54836 14.8309 5.44415L16.2804 3.99462C14.8603 2.73133 13.0293 1.86816 11.0127 1.75V1.75Z"
                        fill="white"
                      ></path>
                      <path
                        d="M9.98744 1.75C7.97083 1.86816 6.13983 2.73133 4.71973 3.99462L6.16925 5.44415C7.21338 4.54836 8.53521 3.97233 9.98744 3.86094V1.75Z"
                        fill="white"
                      ></path>
                      <path
                        d="M3.86094 9.98732C3.97233 8.53509 4.54836 7.21326 5.44415 6.16913L3.99462 4.7196C2.73133 6.1397 1.86816 7.97071 1.75 9.98732H3.86094Z"
                        fill="white"
                      ></path>
                      <path
                        d="M10.4994 8.14844L8.68694 9.96089C8.48716 10.1611 8.48716 10.4861 8.68694 10.6864C8.8817 10.8811 9.21817 10.8802 9.41193 10.6864L10.4994 9.59889L11.5869 10.6864C11.7816 10.8801 12.1186 10.8801 12.3113 10.6864C12.5116 10.4861 12.5116 10.1611 12.3118 9.96085L10.4994 8.14844Z"
                        fill="white"
                      ></path>
                      <path
                        d="M10.5 4.86035C7.3903 4.86035 4.86035 7.3903 4.86035 10.5C4.86035 13.6097 7.3903 16.1397 10.5 16.1397C13.6097 16.1397 16.1397 13.6097 16.1397 10.5C16.1397 7.3903 13.6097 4.86035 10.5 4.86035ZM13.0374 11.4118C12.5066 11.9435 11.6501 12.0303 11.0127 11.5352V13.0635H12.0381V14.0889H8.96192V13.0635H9.98731V11.5093C9.39412 11.9668 8.49909 11.9488 7.96206 11.4118C7.36275 10.8119 7.36275 9.8361 7.96257 9.23631L10.5 6.69885L13.0374 9.23628C13.6373 9.8361 13.6373 10.8119 13.0374 11.4118Z"
                        fill="white"
                      ></path>
                      <path
                        d="M14.8309 15.5559C13.7868 16.4517 12.4649 17.0277 11.0127 17.1391V19.2501C13.0293 19.1319 14.8603 18.2687 16.2804 17.0054L14.8309 15.5559Z"
                        fill="white"
                      ></path>
                      <path
                        d="M15.5557 14.8309L17.0052 16.2804C18.2685 14.8603 19.1317 13.0293 19.2498 11.0127H17.1389C17.0275 12.4649 16.4514 13.7868 15.5557 14.8309Z"
                        fill="white"
                      ></path>
                      <path
                        d="M15.5557 6.16913C16.4514 7.21322 17.0275 8.53509 17.1389 9.98732H19.2498C19.1317 7.97071 18.2685 6.1397 17.0052 4.7196L15.5557 6.16913Z"
                        fill="white"
                      ></path>
                      <path
                        d="M5.44415 14.8309C4.54836 13.7868 3.97233 12.4649 3.86094 11.0127H1.75C1.86816 13.0293 2.73133 14.8603 3.99462 16.2804L5.44415 14.8309Z"
                        fill="white"
                      ></path>
                      <path
                        d="M4.71973 17.0054C6.13983 18.2687 7.97083 19.1319 9.98744 19.2501V17.1391C8.53521 17.0277 7.21338 16.4517 6.16925 15.5559L4.71973 17.0054Z"
                        fill="white"
                      ></path>
                    </g>
                    <defs>
                      <clippath id="clip0_372_3430">
                        <rect
                          width="17.5"
                          height="17.5"
                          fill="white"
                          transform="translate(1.75 1.75)"
                        ></rect>
                      </clippath>
                    </defs>
                  </svg>

                  <span>Live Casino</span>
                </Link>

              </div>
              
            </div>
          </div>

          <div className="hero_bottom__btns hero_bottom__btns__mobile  dis-f jcsb aic">

            <Link
              to="/casino"
              className="hero_bottom__btn btn-blue btn"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.80371 14.8066C8.66679 14.8066 8.53803 14.86 8.4412 14.9568C8.34437 15.0537 8.29102 15.1824 8.29102 15.3193C8.29102 15.4563 8.34437 15.585 8.4412 15.6819C8.53803 15.7787 8.66679 15.8321 8.80371 15.8321C8.94067 15.8321 9.06939 15.7787 9.16625 15.6819C9.36617 15.482 9.36617 15.1567 9.16625 14.9568C9.06939 14.86 8.94067 14.8066 8.80371 14.8066Z"
                  fill="white"
                ></path>
                <path
                  d="M11.1963 7.76562H2.78809C1.93999 7.76562 1.25 8.45561 1.25 9.30371V17.7119C1.25 18.56 1.93999 19.25 2.78809 19.25H11.1963C12.0444 19.25 12.7344 18.56 12.7344 17.7119V9.30371C12.7344 8.45561 12.0444 7.76562 11.1963 7.76562ZM6.26823 12.7839C5.9777 13.0744 5.59147 13.2344 5.18063 13.2344C4.76979 13.2344 4.38356 13.0743 4.09303 12.7839C3.49335 12.1841 3.49335 11.2084 4.09303 10.6087C4.38353 10.3182 4.76976 10.1581 5.1806 10.1581C5.59144 10.1581 5.97767 10.3182 6.26819 10.6087C6.86794 11.2084 6.86794 12.1841 6.26823 12.7839ZM9.89131 16.4069C9.60078 16.6974 9.21455 16.8575 8.80371 16.8575C8.39287 16.8575 8.00664 16.6974 7.71611 16.4069C7.11643 15.8072 7.11643 14.8315 7.71611 14.2317C8.00664 13.9413 8.39287 13.7812 8.80371 13.7812C9.21455 13.7812 9.60078 13.9412 9.89131 14.2318C10.491 14.8315 10.491 15.8072 9.89131 16.4069Z"
                  fill="white"
                ></path>
                <path
                  d="M5.18042 11.1835C5.04349 11.1835 4.91474 11.2369 4.81791 11.3337C4.61799 11.5336 4.61799 11.8589 4.81791 12.0588C4.91474 12.1556 5.04349 12.209 5.18042 12.209C5.31737 12.209 5.44609 12.1556 5.54296 12.0588C5.63976 11.9619 5.69311 11.8332 5.69311 11.6963C5.69311 11.5593 5.63976 11.4305 5.54293 11.3337C5.44609 11.2369 5.31734 11.1835 5.18042 11.1835Z"
                  fill="white"
                ></path>
                <path
                  d="M15.1609 4.82617C15.024 4.82617 14.8952 4.87953 14.7984 4.97636C14.5985 5.17627 14.5985 5.50153 14.7984 5.70145C14.8952 5.79828 15.024 5.85163 15.1609 5.85163C15.2978 5.85163 15.4266 5.79828 15.5234 5.70145C15.6202 5.60458 15.6736 5.47583 15.6736 5.33887C15.6736 5.20194 15.6202 5.07319 15.5234 4.97636C15.4266 4.87953 15.2978 4.82617 15.1609 4.82617Z"
                  fill="white"
                ></path>
                <path
                  d="M11.217 5.70141C11.417 5.50149 11.417 5.17624 11.217 4.97632C11.1202 4.87953 10.9915 4.82617 10.8545 4.82617C10.7176 4.82617 10.5888 4.87953 10.492 4.97636C10.3952 5.07319 10.3418 5.20194 10.3418 5.33887C10.3418 5.47583 10.3952 5.60458 10.492 5.70141C10.5888 5.79824 10.7176 5.8516 10.8545 5.8516C10.9915 5.8516 11.1202 5.79824 11.217 5.70141Z"
                  fill="white"
                ></path>
                <path
                  d="M15.5234 10.008C15.6203 9.91116 15.6736 9.78241 15.6736 9.64548C15.6736 9.50853 15.6203 9.37977 15.5234 9.28294C15.3235 9.08309 14.9983 9.08309 14.7984 9.28294C14.5985 9.48286 14.5985 9.80811 14.7984 10.008C14.8952 10.1049 15.024 10.1582 15.1609 10.1582C15.2978 10.1582 15.4266 10.1048 15.5234 10.008Z"
                  fill="white"
                ></path>
                <path
                  d="M17.2119 1.75H8.80371C7.95561 1.75 7.26562 2.43999 7.26562 3.28809V6.74023H10.2195C10.0532 6.66477 9.89992 6.55949 9.76689 6.42647C9.16721 5.82675 9.16721 4.85099 9.76689 4.25127C10.0574 3.96081 10.4437 3.80078 10.8545 3.80078C11.2653 3.80078 11.6516 3.96078 11.9421 4.2513C12.5418 4.85102 12.5418 5.82678 11.9421 6.4265C11.801 6.56752 11.6373 6.67752 11.4592 6.75367C12.6638 6.87695 13.6241 7.83774 13.7465 9.04258C13.8214 8.86642 13.9301 8.70133 14.0735 8.55791C14.6732 7.95823 15.649 7.95826 16.2487 8.55788C16.8484 9.15763 16.8484 10.1334 16.2487 10.7331C15.9582 11.0236 15.5719 11.1836 15.1611 11.1836C14.7503 11.1836 14.364 11.0236 14.0735 10.7331C13.9384 10.598 13.834 10.4436 13.7597 10.2789V13.2344H17.2119C18.06 13.2344 18.75 12.5444 18.75 11.6963V3.28809C18.75 2.43999 18.06 1.75 17.2119 1.75ZM16.2487 6.42647C15.9582 6.71696 15.5719 6.87699 15.1611 6.87699C14.7503 6.87699 14.364 6.71696 14.0735 6.42647C13.4738 5.82675 13.4738 4.85099 14.0735 4.25127C14.364 3.96081 14.7503 3.80078 15.1611 3.80078C15.572 3.80078 15.9582 3.96078 16.2487 4.2513C16.8484 4.85102 16.8484 5.82675 16.2487 6.42647Z"
                  fill="white"
                ></path>
              </svg>
              <span>Casino</span>
            </Link>

            <Link
              to="/live-casino"
              className="hero_bottom__btn btn-pink  btn"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_372_3430)">
                  <path
                    d="M11.0127 1.75V3.86094C12.4649 3.97233 13.7868 4.54836 14.8309 5.44415L16.2804 3.99462C14.8603 2.73133 13.0293 1.86816 11.0127 1.75V1.75Z"
                    fill="white"
                  ></path>
                  <path
                    d="M9.98744 1.75C7.97083 1.86816 6.13983 2.73133 4.71973 3.99462L6.16925 5.44415C7.21338 4.54836 8.53521 3.97233 9.98744 3.86094V1.75Z"
                    fill="white"
                  ></path>
                  <path
                    d="M3.86094 9.98732C3.97233 8.53509 4.54836 7.21326 5.44415 6.16913L3.99462 4.7196C2.73133 6.1397 1.86816 7.97071 1.75 9.98732H3.86094Z"
                    fill="white"
                  ></path>
                  <path
                    d="M10.4994 8.14844L8.68694 9.96089C8.48716 10.1611 8.48716 10.4861 8.68694 10.6864C8.8817 10.8811 9.21817 10.8802 9.41193 10.6864L10.4994 9.59889L11.5869 10.6864C11.7816 10.8801 12.1186 10.8801 12.3113 10.6864C12.5116 10.4861 12.5116 10.1611 12.3118 9.96085L10.4994 8.14844Z"
                    fill="white"
                  ></path>
                  <path
                    d="M10.5 4.86035C7.3903 4.86035 4.86035 7.3903 4.86035 10.5C4.86035 13.6097 7.3903 16.1397 10.5 16.1397C13.6097 16.1397 16.1397 13.6097 16.1397 10.5C16.1397 7.3903 13.6097 4.86035 10.5 4.86035ZM13.0374 11.4118C12.5066 11.9435 11.6501 12.0303 11.0127 11.5352V13.0635H12.0381V14.0889H8.96192V13.0635H9.98731V11.5093C9.39412 11.9668 8.49909 11.9488 7.96206 11.4118C7.36275 10.8119 7.36275 9.8361 7.96257 9.23631L10.5 6.69885L13.0374 9.23628C13.6373 9.8361 13.6373 10.8119 13.0374 11.4118Z"
                    fill="white"
                  ></path>
                  <path
                    d="M14.8309 15.5559C13.7868 16.4517 12.4649 17.0277 11.0127 17.1391V19.2501C13.0293 19.1319 14.8603 18.2687 16.2804 17.0054L14.8309 15.5559Z"
                    fill="white"
                  ></path>
                  <path
                    d="M15.5557 14.8309L17.0052 16.2804C18.2685 14.8603 19.1317 13.0293 19.2498 11.0127H17.1389C17.0275 12.4649 16.4514 13.7868 15.5557 14.8309Z"
                    fill="white"
                  ></path>
                  <path
                    d="M15.5557 6.16913C16.4514 7.21322 17.0275 8.53509 17.1389 9.98732H19.2498C19.1317 7.97071 18.2685 6.1397 17.0052 4.7196L15.5557 6.16913Z"
                    fill="white"
                  ></path>
                  <path
                    d="M5.44415 14.8309C4.54836 13.7868 3.97233 12.4649 3.86094 11.0127H1.75C1.86816 13.0293 2.73133 14.8603 3.99462 16.2804L5.44415 14.8309Z"
                    fill="white"
                  ></path>
                  <path
                    d="M4.71973 17.0054C6.13983 18.2687 7.97083 19.1319 9.98744 19.2501V17.1391C8.53521 17.0277 7.21338 16.4517 6.16925 15.5559L4.71973 17.0054Z"
                    fill="white"
                  ></path>
                </g>
                <defs>
                  <clippath id="clip0_372_3430">
                    <rect
                      width="17.5"
                      height="17.5"
                      fill="white"
                      transform="translate(1.75 1.75)"
                    ></rect>
                  </clippath>
                </defs>
              </svg>

              <span>Live Casino</span>
            </Link>

          </div>
        </div>

      </div>
    </section>
  );
};
export default CasinoHero;
