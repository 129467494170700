import './faq.css';

const FAQ = () => {
    return (
        <div className='faq-wrapper'>
            <h3>Frequently asked Questions</h3>
            
            <br/>
            <br/>
            Can I register for your casino?
            <br/>
            You must be at least 18 years old or have reached the legal age of majority in your jurisdiction. You must have the right to play online games in accordance with applicable law. For more information, please review our terms and conditions.
            Games can be addictive, so players are advised to maintain self-control.

            <br/>
            <br/>
            Account Information
            <br/>
            <br/>

            What should I do if I forgot my password?
            <br/>
            If you forgot your password, you can reset it within 15 seconds using our "Forgot password" link. After requesting a password reset, please follow the instructions in the email we will send you to reset your password.
            <br/>
            I lost my mobile phone. How do I reset my Google Authenticator settings?
            <br/>
            If you want to remove your 2FA Google Authenticator, please contact us. After we receive your request, for the security of your account, you need to correctly answer several security questions to remove 2FA.

            <br/>
            <br/>
            Can I change my username or registered email address?
            <br/>
            Sorry, but we cannot update this information. If you insist on changing your username and/or registered email address, we suggest you close your current account and register a new one.

            <br/>
            <br/>
            How can I win real money from the online slots?
            <br/>
            Many individuals visit Cross with the hope of winning real money. While some players are there just for fun and to pass the time, many are eager to earn a bit of extra cash.
            <br/>
            To start playing with real money, you must first create a free account and deposit a minimum of $10. Registering on the website is a straightforward process and should be easy if you have previously signed up for other platforms. All you have to do is click the "sign up" button and follow the instructions.
            <br/>
            Before funding your account, it is essential to read the terms and conditions carefully. Once your profile is credited with the money, you can choose from a wide range of games, including slot machines, progressives, instant wins, table games, and live dealer games. Each game offers you the opportunity to play with real money and potentially win a decent cash.

            <br/>
            <br/>
            Wallet
            <br/>
            My Wallet is a part of the player's account. You can view the balance of all interacting cryptocurrencies, as well as fiat money, deposit and withdraw funds, and request transaction records.
            <br/>
            Can I deposit money via a payment method that is not in my name?
            <br/>
            All payment methods used on your player’s account have to legally belong to you. You are not allowed to use any payment method registered in another person’s name.
            <br/>
            My deposit has not been credited after more than 5 days.
            <br/>
            When a deposit is not successful, the funds will normally credit back to your bank account, wallet. Please check the account you used to make the deposit first, to ensure the funds have not been returned.
            <br/>
            If your deposit has not been credited back to your account (wallet), please reach out to us and we will be able to investigate this for you. To help us with this investigation, please provide a bank (wallet) statement showing the deposit leaving your bank account (wallet) 

            <br/>
            <br/>
            What deposit methods can I use?
            <br/>
            At Cross we offer a wide range of payment methods that you can use to make a deposit with us.
            <br/>
            When you go to place your deposit, you will be able to see all the payment methods that are available to you in the dropdown menu. If you cannot see your desired payment method, unfortunately, it means that it is unavailable.
            <br/>
            We offer players the following payment methods:
            •	Debit/credit card
            <br/>
                    Crypto currency
                    <br/>
            •	Bank Transfer
            <br/>
            •	Neteller
            <br/>
            •	Skrill
            <br/>
            •	Paysafe Cards
            <br/>
            Please note that some methods might not be available for your first deposit and that using VISA may not be allowed in your jurisdiction.

            <br/>
            <br/>
            How do I make a deposit?
            <br/>
            It's super simple to make a deposit with Cross! Let us guide you through this process:
            <br/>
            Once logged into your player account, click on the balance in the top right corner on web or bottom right corner on the apps and follow these simple steps: 

            <br/>
            <br/>
            1.	Choose the preferred method of payment from the available options (click on "change" to change the method)
            <br/>
            2.	Choose either one of the pre-chosen amounts or enter your own amount in the window. Please kindly note that if your deposit is suggested to be a deposit fee, the fee amount will show just above this box with the chosen amount. 
            <br/>
            3.	Click "Deposit xx to my account".
            <br/>
            4.	You will now be redirected to a new window for the selected payment provider. Kindly follow the steps shown on the screen.
            Should you encounter any issues with your deposit,  one of the related articles can possibly help. 

            <br/>
            <br/>
            Do you have fees on deposit?
            <br/>
            Cross does not charge any fees on your gaming account deposit. However, there can be fees taken by the payment provider. 
            <br/>
            All possible fees are displayed in the deposit window before completing your deposit. If you have any questions about fees that were debited from your deposit, we recommend that you contact the payment provider. 

            <br/>
            <br/>
            What to do if the game freezes or encounters a problem? 
            If you encounter any technical issues while playing our games, please try refreshing it. Usually, after refreshing game works. If the problem persists, please contact us.

            <br/>
            <br/>
            Support Service always helps you
            <br/>
            Cross's top priority is the safety and security both of its new and existing customers. The casino brand has implemented numerous measures to provide a safe and secure environment, including the latest SSL encryption technology. By joining the platform, you can rest assured that all your personal data and information will be stored securely and protected from any potential threats. Transactions, including deposits and withdrawals, are processed instantly and securely, further ensuring your safety. If you encounter any issues related to safety or if you suspect any fraudulent activity, Bongo's customer support team is available to assist you. So, you can join the platform without any worries and feel confident that you are protected.
        </div>
    )
}
export default FAQ