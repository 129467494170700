import './termsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className='terms-wrapper'>
            Terms of Service
            <br/>
            <br/>
            You (the "User" or "you") should study this end user agreement (the "Agreement") carefully before using any of Cross Gaming's services or products. Please take note that the Agreement is a contract between you and Cross Gaming, the business that owns and runs the website you can find at Cross Gaming and the games listed there. Cross Gaming is referred to herein as "Cross Gaming", "us," or "we" (the "Service"). You accept the terms and conditions stated in this Agreement by clicking the "I Agree" option, if and when offered, and/or by employing the Service.

            <br/>
            <br/>
            1. Grant of License
            <br/>
            Cross Gaming offers the User a non-exclusive, personal, non-transferable license to use the Service on their computer or another Internet-connected device to access the games, according to the terms and conditions stated in this Agreement.
            <br/>
            1.2. The Service is not intended for use by (I) people under the age of 18, (ii) people under the legal age of majority in their jurisdiction, and (iii) anyone accessing the Service from countries where doing so is against the law. It is the User's obligation to confirm that their usage of the Service is permitted since Cross Gaming cannot guarantee the Service's validity in every area.
            <br/>
            1.3. All copyrights in and to the Service, its technology, design, and organization, including trademark, trade secrets, intellectual property, and other legal protections, are owned solely by Cross Gaming and its licensors. Within the bounds permitted by relevant laws, you may not: (a) copy, transfer, distribute, reprogram, deconstruct, disassemble, alter, or translate the website; (b) use the service in a way that is contrary to such statutes or regulations (each of the above is an "Unauthorized Use"). All implicit and other claims not explicitly given to the User hereof, are reserved by Cross Gaming, which also retains all rights, credit, and interest in and to the Service. You acknowledge that any harm, expenditure, or cost resulting from any unauthorized use you commit will be your responsibility. When you learn someone has committed an Unauthorized Use, you must tell Cross Gaming immediately. You must also give Cross Gaming practical support for any investigations it conducts using your provided information.
            <br/>
            1.4. Cross Gaming is the exclusive owner of the title "Cross Gaming", its domain names, and any other trade names or service marks that it uses in connection with the Service (collectively, the "Trade Marks"). Also, Cross Gaming owns all of the website's content, which is covered by copyright and/or other intellectual property or other rights. It includes, but is not restricted to, any illustrations, photos, graphics, animations, videos, music, audio, and text (collectively, the "Site Content"). You, therefore, recognize that you do not acquire any rights in the Site Content, the Trade Marks, or any aspect of either by employing the Service. You may only use the Trade Marks or the Site Content with Cross Gaming's prior written permission. You also refrain from taking any actions that might endanger Cross Gaming's rights, mainly its intellectual property rights.
            <br/>
            <br/>
            2. No Warranties
            <br/>
            2.1. Cross Gaming makes no explicit or implied warranties concerning the service offered to you "as is." We do not guarantee the service's caliber, suitability, thoroughness, or correctness for your needs.
            <br/>
            2.2. Notwithstanding its best efforts, Cross Gaming does not guarantee that the service will be timely, fault-free, or unbroken or that any bugs will be fixed.
            <br/>
            <br/>
            3. Authority/Terms of Service
            <br/> 
            You accept the game regulations provided on the Cross Gaming website. The issuance, upkeep, and termination of the Service remain under the control of Cross Gaming. The administration of Cross Gaming reserves the right to make final decisions about any Service usage or dispute resolution that are not subject to review or appeal.
            <br/>
            <br/>
            4. Your Obligations as a Player
            <br/>
            4.1. You hereby declare and warrant that:
            <br/>
            4.1.1. You are old enough to legally play the games on the website under the rules that apply to you, such as the minimum legal age in your place of citizenship (e.g., in Estonia is 21 years old).
            <br/>
            4.1.2. You only participate in the Games for fun and amusement in your individual, non-professional abilities.
            <br/>
            4.1.3. You participate in the Games solely for your benefit and not for anybody else.
            <br/>
            4.1.4. You will ensure that all the information you give Cross Gaming is accurate, up-to-date, and truthful and will tell Cross Gaming as soon as any of this information changes.
            <br/>
            4.1.5. You are exclusively responsible for declaring and keeping track of any taxes or earnings from Cross Gaming that apply to you under applicable legislation.
            <br/>
            4.1.6. You are aware that playing the Games exposes you to the possibility of losing Virtual Money in Your Member Account.
            <br/>
            4.1.7. You must not use any software-assisted approaches, techniques, or hardware devices to participate in any Games. You must not engage in any dishonest, collusive, fixing, or other unlawful criminal conduct in connection with your involvement in any of the Games or that of third parties. In the event of such behavior, Cross Gaming retains the right to void any wager.
            <br/>
            4.1.8. You are aware that virtual currencies like Bitcoin are not recognized as legal cash or money, and they are handled as such on the website, having no inherent value.
            <br/>
            4.1.9. You are aware that the market value of Bitcoin can cause its worth to fluctuate significantly.
            <br/>
            4.1.10. It is not permitted for you to utilize any payment options that a third party or individual owns.
            <br/>
            4.2. Transferring, selling, and/or acquiring user accounts is prohibited.
            <br/>
            4.3. Games played on Our site should be played in the same way they would be elsewhere. So, players must treat one another respectfully and refrain from using foul language.
            <br/>
            4.5. Must the user learn of any mistakes or gaps in the program, they undertake to forego exploiting them. The user also promises to notify Cross Gaming right away of any inaccuracy or incompleteness. If the user does not comply with the requirements outlined in this agreement, Cross Gaming is entitled to full reimbursement for all expenses associated with the error or incompleteness, including any costs linked to the specific fault/incompleteness and the user's failure to notice.
            <br/>
            4.6. If a game is initiated but terminated due to a system malfunction, Cross Gaming shall refund the User's Account the amount wagered in the game. It will be reimbursed to the User in an authorized way if the account no longer exists. If the user had a credit balance when the game failed, Zini Casino refunds the amount of the credit to the user's account or, if the user's account is no longer active, pays the user the reward in a way that has been agreed.
            <br/>
            4.7. Cross Gaming retains the right to refuse or restrict bets. The user is not allowed to gamble more than the amount of their account. Winnings are credited to the player's private account.
            <br/>
            4.8. Cross Gaming has the right to withhold payments if there is reason to believe or solid proof that the casino system has been manipulated. Any user or other individual who has attempted to manipulate the casino system will be charged criminally. Any games or activities offered on the website may end or modify at Cross Gaming's discretion.
            <br/>
            In the event of questionable or fraudulent transactions, we retain the right to demand some documentation.
            <br/>
            4.10. Cross Gaming retains the right to cancel all or part of a wager if Cross Gaming determines, in its sole discretion, that any of the following events has occurred:
            <br/>
            4.10.1. You, or those close to you, may directly or indirectly affect an event's result to gain an unfair advantage,
            <br/>
            4.10.2. You and/or others connected to you evade Cross Gaming regulations directly or indirectly.
            <br/>
            4.10.3. The outcome of an event has been adversely impacted by criminal conduct, either directly or indirectly.
            <br/>
            4.10.4. The outcome of an event has been adversely impacted by criminal conduct, either directly or indirectly.
            <br/>
            4.10.5. Wagers that would not have been accepted generally but were allowed when the website was experiencing technical difficulties were placed.
            <br/>
            4.10.6. Wagers have been offered, placed, and/or accepted owing to a mistake, such as a typographical error, technical error, force majeure, or another mistake.
            <br/>
            4.10.7. Cross Gaming reserves the right to take away prizes if a player's deposit cost is too low and is identified by blockchain or a similar site as "not enough fee to relay", if Cross Gaming, in its sole discretion, judges the transaction and the player's actions to be fraudulent.
            <br/>
            4.11. If you sign a self-exclusion agreement with a gaming operator, you must immediately notify Cross Gaming.

            <br/>
            <br/>
            5. Prohibited Uses
            <br/>
            5.1. PERSONAL USE. The User may use the Service only for personal purposes. The User may only wager for their amusement and may not open multiple accounts, including for collusion and/or service abuse.
            <br/>
            5.2. JURISDICTIONS. The Service may not be used by those physically present in or inhabitants of Aruba, Bonaire, Curaçao, Costa Rica, France, the Netherlands, Saba, Statia, St. Martin, or the USA (the "Prohibited Jurisdictions"). To avoid doubt, residents, and citizens of other countries in Restricted Jurisdictions are subject to the same limitations on participating in real-money play as those listed above. Any effort to get around the play limitations by anybody residing in a barred or limited jurisdiction violates this contract. A circumvention effort includes, but is not limited to, altering the data Cross Gaming uses to pinpoint your location and giving Cross Gaming inaccurate or deceptive information about your location or residence.

            <br/>
            <br/>
            6. Know your Customer ("KYC")
            <br/>
            Any KYC documents that Cross Gaming deems required to establish a User's identity and location may be requested at any time. Until identification can be satisfactorily found, Cross Gaming reserves the right to impose restrictions on service and payment.

            <br/>
            <br/>
            7. Breach
            <br/>
            7.1. Without limiting any other rights, Cross Gaming reserves the right to take whatever action it deems appropriate, including terminating this Agreement or any other contract with the User and/or pursuing legal action against the User if a User violates any provision of this Agreement in whole or in part.
            <br/>
            7.2. You undertake to keep Cross Gaming and its shareholders, directors, representatives, and workers blameless from and against any complaints, demands, obligations, damages, losses, obligations, and expenses, including attorney fees and other costs of any kind whatsoever caused, that may stem from: (i) Your violation of this Agreement, entirely or partially (ii) Your breach of any legislation or the claims of any third party (iii) Your use of the Service.

            <br/>
            <br/>
            8. Limitations and Liability
            <br/>
            8.1. Even if Cross Gaming had foreknowledge of the prospect of such harm, under no conditions, including malpractice, shall Cross Gaming be liable for any special, inadvertent, direct, indirect, or impactful losses of any kind (including, without limitation, damages for loss of business profits, economic loss, loss of business information, or any other monetary loss) arising out of the use (or misuse) of the Service.
            <br/>
            8.2. This Agreement does not restrict or exclude Cross Gaming's liability for wrongful death or bodily harm brought on by its carelessness.

            <br/>
            <br/>
            9. Disputes
            <br/>
            Please email the Cross Gaming customer support department if a User has a problem. If a disagreement is not settled to your satisfaction, you may seek redress in the jurisdiction where applicable legislation is set out.

            <br/>
            <br/>
            10. Amendment
            <br/>
            You shall be bound by any updated or modified terms of service when posted by Cross Gaming, who retains the right to update or modify this Agreement or any part of it at any time or make other changes to the Service without prior notice. Thus, we advise you to review the terms and conditions in the version of the Agreement that is currently in effect. Any changes to the Agreement will be considered accepted if you continue to use the Service.

            <br/>
            <br/>
            11. Governing Law
            <br/>
            The laws of Costa Rica shall govern the Agreement and all relevant aspects and shall be followed in their interpretation. You, with this, irrevocably consent to the courts of Costa Rica having exclusive jurisdiction over any claim, dispute, or difference relating to the Agreement and any matter arising from that place, subject to the provisions set forth below, and irrevocably waive any right it may have to object to an action being brought in those courts, to claim that the action has been brought in an inconvenient forum, or to assert that those courts lack jurisdiction. Nothing in this clause limits Cross Gaming's ability to file a lawsuit against you in another court of competent jurisdiction, and filing a lawsuit in one or more jurisdictions does not preclude filing a lawsuit in another jurisdiction, concurrently or not, to the extent that doing so is allowed by that other jurisdiction's law.
            <br/>
            The legality, validity, or enforceability of any other provision of this Agreement in that jurisdiction or in any other jurisdiction shall not be affected by the illegality, invalidity, or unenforceability of any provision of this Agreement in any jurisdiction.

            <br/>
            <br/>
            12. Assignment
            <br/>
            The right to assign this contract in whole or in part, without prior notification, is reserved by Cross Gaming. None of the User's rights or responsibilities under this Agreement may be transferred.

            <br/>
            <br/>
            13. Advantage play
            <br/>
            If the casino learns that a player has accepted a bonus or a promotion solely to increase their expected bonus return through the use of well-known strategies to cash out the bonus or in any other way attempt to take advantage of bonuses given to Cross Gaming, Cross Gaming will immediately confiscate any winnings and close the player's account, with the option to withhold any further withdrawals. An example of advantage play is postponing any game round, including free spins and bonus rounds, until after the wagering requirement has been met and/or making further deposits while a game's free spins or bonus rounds are still active. Equal, zero, low margin, or hedge betting will all be regarded as irregular gaming for the bonus play-through requirement in the interest of fair gaming. If the casino determines that there has been a foul game play, it retains the right to halt any withdrawals and/or seize all profits.
        </div>
    )
}
export default TermsAndConditions;