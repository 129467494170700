import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./header.css";

//import HeaderLogo from '../../assets/header_logo.png';
import HeaderLogo from "../../assets/CrossGaming.png";
import Favourite from "../../assets/icons/favourite.svg";
import Notifications from "../../assets/icons/notifications.svg";
import SearchIcon from "../../assets/icons/search.svg";
import ProfileIcon from "../../assets/user.png";
import PromotionsIcon from "../../assets/icons/promotions.svg";
import EnglishIcon from "../../assets/icons/en-En.svg";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/AuthContext";
import HeaderBottom from "./HeaderBottom";

const Header = () => {
  const { user, isAuthenticated, logoutUser } = useContext(AuthContext);
  let menuRef = useRef();

  const { t } = useTranslation();
  const [profileMenu, setProfileMenu] = useState(false);
  const [profileMenuBtn, setProfileMenuBtn] = useState("header__user_btn");
  const [menuActive, setMenuActive] = useState("header__user_menu list-reset");

  // Profile Drop Down Menu function
  const profileDropDownMenu = () => {
    if (profileMenu) {
      setProfileMenu(false);
      setProfileMenuBtn("header__user_btn");
      setMenuActive("header__user_menu list-reset");
      //console.log("False: " + profileMenu, menuActive);
    } else {
      setProfileMenu(true);
      setProfileMenuBtn("header__user_btn active");
      setMenuActive("header__user_menu list-reset active");
      //console.log("True: " + profileMenu, menuActive);
    }
  };

  /*useEffect(() => {
  }, [user]);*/

  // Popup Menu
  useEffect( () => {
    let handler = (event) => {
      if (profileMenu && menuRef.current && !menuRef.current.contains(event.target)) {
        profileDropDownMenu();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [profileMenu]);

  return (
    <header className="header">
      
      <div className="header__top">
        <div className="header__container">
          <div className="header__top__wrapper dis-f aic">
            <Link to="/" className="header__logo">
              <img loading="lazy" src={HeaderLogo} alt="Cross" />
            </Link>
            <input
              type="text"
              className="header__search"
              placeholder={t("headerMenu.search-games")}
            />
            <Link to="/promotion" className="header__promotions">
              <div className="header__promotions__img">
                <img loading="lazy" src={PromotionsIcon} alt="promotions" />
              </div>
              {t("headerMenu.promotions-and-bonuses")}
            </Link>
            <div className="header__language dis-f aic">
              <div className="header__language_flag">
                <img loading="lazy" src={EnglishIcon} alt="en" />
              </div>
              <div className="header__language_current">EN</div>
              <div className="header__language_btn">
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 8.37498L0 2.37498L1.4 0.974976L6 5.57498L10.6 0.974976L12 2.37498L6 8.37498Z"
                    fill="white"
                  ></path>
                </svg>
              </div>
            </div>

            <Link to="/" className="header__favourite">
              <img loading="lazy" src={Favourite} alt="favourite" />
            </Link>
            <Link to="/" className="header__notifications">
              <img loading="lazy" src={Notifications} alt="notifications" />
            </Link>
            <div className="mobile_search">
              <img src={SearchIcon} alt="" />
            </div>
            <div className="header__user dis-f aic">
              <div className="header__user_left">
                <div className="header__balance">
                  <p>
                    {user == null ? "0" : user.balance}
                    <span
                      style={{ textTransform: "uppercase", marginLeft: "2px" }}
                    >
                      {user == null ? "EURO" : user.currency}
                    </span>
                  </p>
                  Balance
                </div>
              </div>
              <div className="header__user_right dis-f aic" ref={menuRef}>
                <img
                  loading="lazy"
                  src={ProfileIcon}
                  alt="user"
                  className="header__user_avatar"
                />

                {/* Profile Drop Down Button */}
                <div className={profileMenuBtn} onClick={profileDropDownMenu}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.657 7.929L17.314 13.586L15.9 15L11.657 10.757L7.414 15L6 13.586L11.657 7.929Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>

                {/* Profile Drop Down Menu */}
                <ul className={menuActive} onClick={profileDropDownMenu}>
                  <li>
                    <div className="header_item__wrapper">
                      <Link to="/">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 12H1.33333C0.6 12 0 11.4 0 10.6667V1.33333C0 0.6 0.6 0 1.33333 0H4C4.73333 0 5.33333 0.6 5.33333 1.33333V10.6667C5.33333 11.4 4.73333 12 4 12ZM8 12H10.6667C11.4 12 12 11.4 12 10.6667V7.33333C12 6.6 11.4 6 10.6667 6H8C7.26667 6 6.66667 6.6 6.66667 7.33333V10.6667C6.66667 11.4 7.26667 12 8 12ZM12 3.33333V1.33333C12 0.6 11.4 0 10.6667 0H8C7.26667 0 6.66667 0.6 6.66667 1.33333V3.33333C6.66667 4.06667 7.26667 4.66667 8 4.66667H10.6667C11.4 4.66667 12 4.06667 12 3.33333Z"
                            fill="none"
                          ></path>
                        </svg>
                        Dashboard
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>

                    <ul className="header__user_submenu list-reset">
                      <li>
                        <Link to="withdrawal">
                          <span>Withdraw</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="header_item__wrapper">
                      <Link to="deposit" id="deposit">
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.0664 3.33604C13.0297 3.33337 12.9891 3.33337 12.9464 3.33337H11.2631C9.88441 3.33337 8.70508 4.41871 8.70508 5.83337C8.70508 7.24804 9.88508 8.33337 11.2631 8.33337H12.9464C12.9891 8.33337 13.0297 8.33337 13.0677 8.33071C13.3511 8.31362 13.6186 8.19411 13.8203 7.99443C14.0221 7.79475 14.1444 7.52853 14.1644 7.24537C14.1671 7.20537 14.1671 7.16204 14.1671 7.12204V4.54471C14.1671 4.50471 14.1671 4.46137 14.1644 4.42137C14.1444 4.13822 14.0221 3.872 13.8203 3.67232C13.6186 3.47264 13.3511 3.35313 13.0677 3.33604H13.0664ZM11.1144 6.50004C11.4691 6.50004 11.7564 6.20137 11.7564 5.83337C11.7564 5.46537 11.4691 5.16671 11.1144 5.16671C10.7591 5.16671 10.4717 5.46537 10.4717 5.83337C10.4717 6.20137 10.7591 6.50004 11.1144 6.50004Z"
                            fill="none"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.946 9.33333C12.969 9.33241 12.992 9.33693 13.0129 9.34654C13.0339 9.35614 13.0523 9.37056 13.0667 9.38861C13.081 9.40667 13.0909 9.42786 13.0956 9.45045C13.1002 9.47305 13.0994 9.49642 13.0933 9.51867C12.96 9.99333 12.7473 10.3987 12.4066 10.7387C11.9073 11.2387 11.2747 11.4593 10.4933 11.5647C9.73332 11.6667 8.76332 11.6667 7.53798 11.6667H6.12998C4.90465 11.6667 3.93398 11.6667 3.17465 11.5647C2.39332 11.4593 1.76065 11.238 1.26132 10.7393C0.762651 10.24 0.541318 9.60733 0.435984 8.826C0.333984 8.066 0.333984 7.096 0.333984 5.87067V5.796C0.333984 4.57067 0.333984 3.6 0.435984 2.84C0.541318 2.05867 0.762651 1.426 1.26132 0.926667C1.76065 0.428 2.39332 0.206667 3.17465 0.101333C3.93465 -2.98023e-08 4.90465 0 6.12998 0H7.53798C8.76332 0 9.73398 7.94729e-08 10.4933 0.102C11.2747 0.207333 11.9073 0.428667 12.4066 0.927333C12.7473 1.26867 12.96 1.67333 13.0933 2.148C13.0994 2.17025 13.1002 2.19362 13.0956 2.21621C13.0909 2.23881 13.081 2.26 13.0667 2.27805C13.0523 2.29611 13.0339 2.31053 13.0129 2.32013C12.992 2.32974 12.969 2.33426 12.946 2.33333H11.2633C9.37198 2.33333 7.70532 3.82667 7.70532 5.83333C7.70532 7.84 9.37198 9.33333 11.2633 9.33333H12.946ZM3.66732 8.33333C3.53471 8.33333 3.40753 8.28065 3.31376 8.18689C3.22 8.09312 3.16732 7.96594 3.16732 7.83333V3.83333C3.16732 3.70073 3.22 3.57355 3.31376 3.47978C3.40753 3.38601 3.53471 3.33333 3.66732 3.33333C3.79993 3.33333 3.9271 3.38601 4.02087 3.47978C4.11464 3.57355 4.16732 3.70073 4.16732 3.83333V7.83333C4.16732 7.96594 4.11464 8.09312 4.02087 8.18689C3.9271 8.28065 3.79993 8.33333 3.66732 8.33333Z"
                            fill="none"
                          ></path>
                        </svg>
                        <span>Deposit</span>
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <ul className="header__user_submenu list-reset">
                      <li>
                        <Link to="profile/history">
                          <span>History</span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <div className="header_item__wrapper">
                      <Link to="withdrawal" id="withdraw">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.19961 3.19995C2.77526 3.19995 2.3683 3.36852 2.06824 3.66858C1.76818 3.96864 1.59961 4.3756 1.59961 4.79995V7.99995C1.59961 8.4243 1.76818 8.83126 2.06824 9.13132C2.3683 9.43138 2.77526 9.59995 3.19961 9.59995V4.79995H11.1996C11.1996 4.3756 11.031 3.96864 10.731 3.66858C10.4309 3.36852 10.024 3.19995 9.59961 3.19995H3.19961ZM4.79961 7.99995C4.79961 7.5756 4.96818 7.16864 5.26824 6.86858C5.5683 6.56852 5.97526 6.39995 6.39961 6.39995H12.7996C13.224 6.39995 13.6309 6.56852 13.931 6.86858C14.231 7.16864 14.3996 7.5756 14.3996 7.99995V11.2C14.3996 11.6243 14.231 12.0313 13.931 12.3313C13.6309 12.6314 13.224 12.8 12.7996 12.8H6.39961C5.97526 12.8 5.5683 12.6314 5.26824 12.3313C4.96818 12.0313 4.79961 11.6243 4.79961 11.2V7.99995ZM9.59961 11.2C10.024 11.2 10.4309 11.0314 10.731 10.7313C11.031 10.4313 11.1996 10.0243 11.1996 9.59995C11.1996 9.1756 11.031 8.76864 10.731 8.46858C10.4309 8.16852 10.024 7.99995 9.59961 7.99995C9.17526 7.99995 8.7683 8.16852 8.46824 8.46858C8.16818 8.76864 7.99961 9.1756 7.99961 9.59995C7.99961 10.0243 8.16818 10.4313 8.46824 10.7313C8.7683 11.0314 9.17526 11.2 9.59961 11.2Z"
                            fill="none"
                          ></path>
                        </svg>
                        Withdraw
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="header_item__wrapper">
                      <Link to="profile/referals">
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0.666626C8.61884 0.666626 9.21233 0.912459 9.64992 1.35004C10.0875 1.78763 10.3333 2.38112 10.3333 2.99996C10.3333 3.6188 10.0875 4.21229 9.64992 4.64988C9.21233 5.08746 8.61884 5.33329 8 5.33329C7.38116 5.33329 6.78767 5.08746 6.35008 4.64988C5.9125 4.21229 5.66667 3.6188 5.66667 2.99996C5.66667 2.38112 5.9125 1.78763 6.35008 1.35004C6.78767 0.912459 7.38116 0.666626 8 0.666626ZM3.33333 2.33329C3.70667 2.33329 4.05333 2.43329 4.35333 2.61329C4.25333 3.56663 4.53333 4.51329 5.10667 5.25329C4.77333 5.89329 4.10667 6.33329 3.33333 6.33329C2.8029 6.33329 2.29419 6.12258 1.91912 5.74751C1.54405 5.37243 1.33333 4.86373 1.33333 4.33329C1.33333 3.80286 1.54405 3.29415 1.91912 2.91908C2.29419 2.54401 2.8029 2.33329 3.33333 2.33329ZM12.6667 2.33329C13.1971 2.33329 13.7058 2.54401 14.0809 2.91908C14.456 3.29415 14.6667 3.80286 14.6667 4.33329C14.6667 4.86373 14.456 5.37243 14.0809 5.74751C13.7058 6.12258 13.1971 6.33329 12.6667 6.33329C11.8933 6.33329 11.2267 5.89329 10.8933 5.25329C11.4746 4.50291 11.7444 3.55742 11.6467 2.61329C11.9467 2.43329 12.2933 2.33329 12.6667 2.33329ZM3.66667 9.16663C3.66667 7.78663 5.60667 6.66663 8 6.66663C10.3933 6.66663 12.3333 7.78663 12.3333 9.16663V10.3333H3.66667V9.16663ZM0 10.3333V9.33329C0 8.40663 1.26 7.62663 2.96667 7.39996C2.57333 7.85329 2.33333 8.47996 2.33333 9.16663V10.3333H0ZM16 10.3333H13.6667V9.16663C13.6667 8.47996 13.4267 7.85329 13.0333 7.39996C14.74 7.62663 16 8.40663 16 9.33329V10.3333Z"
                            fill="none"
                          ></path>
                        </svg>
                        Referrals
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="header_item__wrapper">
                      <Link to="profile/reports">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0ZM4 9.33333H2.66667V4.66667H4V9.33333ZM6.66667 9.33333H5.33333V2.66667H6.66667V9.33333ZM9.33333 9.33333H8V6.66667H9.33333V9.33333Z"
                            fill="none"
                          ></path>
                        </svg>
                        Reports
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="header_item__wrapper">
                      <Link to="support">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 13.6667L5.83333 11.6667H5.66667C4.08889 11.6667 2.75 11.1167 1.65 10.0167C0.55 8.91671 0 7.57782 0 6.00004C0 4.42226 0.55 3.08337 1.65 1.98337C2.75 0.883374 4.08889 0.333374 5.66667 0.333374C6.45555 0.333374 7.19178 0.480485 7.87533 0.774707C8.55889 1.06893 9.15889 1.47449 9.67533 1.99137C10.1918 2.50826 10.5971 3.10826 10.8913 3.79137C11.1856 4.47448 11.3329 5.21071 11.3333 6.00004C11.3333 6.83337 11.1971 7.63337 10.9247 8.40004C10.6522 9.16671 10.28 9.87782 9.808 10.5334C9.336 11.1889 8.77489 11.7834 8.12467 12.3167C7.47444 12.85 6.76622 13.3 6 13.6667ZM5.65 9.65004C5.83889 9.65004 6 9.58337 6.13333 9.45004C6.26667 9.31671 6.33333 9.1556 6.33333 8.96671C6.33333 8.77782 6.26667 8.61671 6.13333 8.48337C6 8.35004 5.83889 8.28337 5.65 8.28337C5.46111 8.28337 5.3 8.35004 5.16667 8.48337C5.03333 8.61671 4.96667 8.77782 4.96667 8.96671C4.96667 9.1556 5.03333 9.31671 5.16667 9.45004C5.3 9.58337 5.46111 9.65004 5.65 9.65004ZM5.16667 7.53337H6.16667C6.16667 7.20004 6.2 6.96671 6.26667 6.83337C6.33333 6.70004 6.54444 6.4556 6.9 6.10004C7.1 5.90004 7.26667 5.68337 7.4 5.45004C7.53333 5.21671 7.6 4.96671 7.6 4.70004C7.6 4.13337 7.40822 3.70826 7.02467 3.42471C6.64111 3.14115 6.18844 2.9996 5.66667 3.00004C5.17778 3.00004 4.76667 3.13626 4.43333 3.40871C4.1 3.68115 3.86667 4.0116 3.73333 4.40004L4.66667 4.76671C4.72222 4.57782 4.82778 4.3916 4.98333 4.20804C5.13889 4.02448 5.36667 3.93293 5.66667 3.93337C5.96667 3.93337 6.19156 4.01671 6.34133 4.18337C6.49111 4.35004 6.56622 4.53337 6.56667 4.73337C6.56667 4.92226 6.51111 5.09182 6.4 5.24204C6.28889 5.39226 6.15556 5.54493 6 5.70004C5.61111 6.03337 5.37489 6.29737 5.29133 6.49204C5.20778 6.68671 5.16622 7.03382 5.16667 7.53337Z"
                            fill="none"
                          ></path>
                        </svg>
                        Support
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="header_item__wrapper">
                      <Link to="profile">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.00065 0.333374C3.32065 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.32065 13.6667 7.00065 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 7.00065 0.333374ZM7.00065 3.00004C8.28732 3.00004 9.33398 4.04671 9.33398 5.33337C9.33398 6.62004 8.28732 7.66671 7.00065 7.66671C5.71398 7.66671 4.66732 6.62004 4.66732 5.33337C4.66732 4.04671 5.71398 3.00004 7.00065 3.00004ZM7.00065 12.3334C5.64732 12.3334 4.04732 11.7867 2.90732 10.4134C4.07505 9.49719 5.5164 8.99924 7.00065 8.99924C8.4849 8.99924 9.92625 9.49719 11.094 10.4134C9.95398 11.7867 8.35398 12.3334 7.00065 12.3334Z"
                            fill="none"
                          ></path>
                        </svg>
                        Account
                      </Link>
                      <div className="user_menu__arrow">
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.60664 4.30619L2.00664 6.90619C1.68997 7.22286 1.32764 7.29352 0.919641 7.11819C0.511641 6.94286 0.307307 6.63052 0.306641 6.18119L0.30664 1.03119C0.30664 0.58119 0.510974 0.268523 0.91964 0.0931897C1.32831 -0.0821438 1.69064 -0.0111442 2.00664 0.306189L4.60664 2.90619C4.70664 3.00619 4.78164 3.11452 4.83164 3.23119C4.88164 3.34786 4.90664 3.47286 4.90664 3.60619C4.90664 3.73952 4.88164 3.86452 4.83164 3.98119C4.78164 4.09786 4.70664 4.20619 4.60664 4.30619Z"
                            fill="none"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link onClick={logoutUser}>
                      <svg
                        width="12"
                        height="14"
                        viewBox="0 0 12 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.5"
                          d="M4.03467 2C4 2.38533 4 2.86933 4 3.48133V10.5187C4 11.1307 4 11.6147 4.03467 12H3.33333C1.762 12 0.976 12 0.488 11.512C7.94729e-08 11.0233 0 10.238 0 8.66667V5.33333C0 3.762 7.94729e-08 2.976 0.488 2.488C0.976 2 1.762 2 3.33333 2H4.03467Z"
                          fill="none"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.47133 0.60596C4 1.02396 4 1.78863 4 3.31729V10.6826C4 12.2113 4 12.976 4.47133 13.394C4.94267 13.812 5.66333 13.6866 7.10467 13.4353L8.658 13.1646C10.254 12.886 11.052 12.7466 11.526 12.1613C12 11.5753 12 10.7286 12 9.03463V4.96529C12 3.27196 12 2.42529 11.5267 1.83929C11.052 1.25396 10.2533 1.11463 8.65733 0.836627L7.10533 0.565293C5.664 0.31396 4.94333 0.188627 4.472 0.606627L4.47133 0.60596ZM6.5 6.30263C6.5 6.01329 6.276 5.77929 6 5.77929C5.724 5.77929 5.5 6.01329 5.5 6.30196V7.69796C5.5 7.98663 5.724 8.22063 6 8.22063C6.276 8.22063 6.5 7.98663 6.5 7.69796V6.30196V6.30263Z"
                          fill="none"
                        ></path>
                      </svg>
                      Log out
                    </Link>
                  </li>
                  <div className="mobile_bottom">
                    <div className="header__language dis-f aic">
                      <div className="header__language_flag">
                        <img
                          loading="lazy"
                          src="./Casino_files/en-En.svg"
                          alt=""
                        />
                      </div>
                      <div className="header__language_current">EN</div>
                      <div className="header__language_btn">
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 8.37498L0 2.37498L1.4 0.974976L6 5.57498L10.6 0.974976L12 2.37498L6 8.37498Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="mobile_support">Support</div>
                  </div>
                </ul>

              </div>
            </div>
            <div className="mobile_burger " onClick={profileDropDownMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <div className="header__center">
        <div className="header__container">
          <ul className="header__center_items list-reset dis-f aic">
            <li className="header__center_item">
              Balance:{" "}
              <span style={{ textTransform: "uppercase", marginLeft: "2px" }}>
                {user == null ? "0" : user.balance}{" "}
                {user == null ? "EURO" : user.currency}
              </span>
            </li>
            <li className="header__center_item">
              Money: <span>16.03 USD</span>
            </li>
            <li className="header__center_item">
              Bonus: <span>430.7 USD</span>
            </li>
            <li className="header__center_item">
              Level: <span>Begginer</span>
            </li>
            <li className="header__center_item">
              Avilable to Withdraw: <span>16.03 USD</span>
            </li>
          </ul>
        </div>
      </div>

      <HeaderBottom />

    </header>
  );
};

export default Header;
