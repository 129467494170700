import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
//import App from "./App";
import "./i18n";

import "./styles/choices.min.css";
import "./styles/countrySelect.min.css";
//import './styles/image-uploader.min.css'
import "./styles/intlTelInput.css";
//import './styles/jquery-ui.min.css';
import "./styles/reset.css";
import "./styles/simplebar.css";
import "./styles/sitebar.css";
import "./styles/style.css";
import "./styles/swiper-bundle.min.css";


import { AuthContextProvider } from "./context/AuthContext";
import RouterComponent from "./RouterComponent ";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <AuthContextProvider>
        <RouterComponent />
      </AuthContextProvider>
    </BrowserRouter>
);
