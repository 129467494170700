import './amlKycPolicy.css';

const AmlKycPolicy = () => {
    return (
        <div className="AmlKyc-wrapper">
        <h3>Deposit</h3>
        <br/>
        <br/>
        Anti-money laundering policy (AML Policy) / Know Your Customer Policy (KYC Policy)
        <br/>
        <br/>

        The Know Your Customer Policy (KYC Policy) and the Anti-Money Laundering Policy (AML Policy) are important tools that help the Services to protect themselves from fraud and prevent any illegal activity. The following are the main principles of KYC and AML policies (AML /KYC Policies) that are implemented in Services:
        <br/>
        <br/>
        1. Customer identification Cross checks the identity of each client and their financial history to exclude the possibility of a client being associated with money laundering or terrorist financing. This check takes place before the start of the game and during the customer service.
        <br/>
        <br/>
        2. Age verification Cross verifies the age of its customers to ensure that they have reached the minimum gambling age. Various methods can be used to do this, such as requesting proof of age or using verified databases.
        <br/>
        <br/>
        3. Transaction monitoring systems  uses transaction monitoring systems to identify suspicious transactions and activities related to money laundering or terrorist financing.
        <br/>
        <br/>
        4. Checking the source of funds  can check the source of the funds the client is using to play. For this, additional information about income, tax returns, bank accounts, etc. may be requested. If there are suspicions that the client is using illegal funds for the game, information about this will be transferred to law enforcement agencies.
        <br/>
        <br/>
        5. Personnel training  personnel are trained on how to effectively identify clients, check the sources of their funds, recognize suspicious transactions and activities related to money laundering or terrorist financing, and how to act in such cases.
        <br/>
        <br/>
        6. Security  provides a set of measures to ensure the safety of its customers and protect them in a timely manner from fraud and identity theft in an appropriate way.
        <br/>
        <br/>
        7. Compliance Cross complies with anti-money laundering and anti-terrorist financing laws, including reporting and information-sharing requirements with law enforcement.
        <br/>
        <br/>
        8. Monitoring client accounts Cross monitors client accounts and transactions in order to promptly identify suspicious activities or transactions related to money laundering or terrorist financing.
        <br/>
        <br/>
        9. Monitoring customer activity Cross monitors the activity of its clients in order to detect suspicious activity, such as large deposits or withdrawals of funds, increased activity during certain periods of time, etc. If a client is suspected of engaging in illegal operations, steps will be taken to prevent such activity.
        <br/>
        <br/>
        10. Regular update of AML /KYC policies  regularly updates its AML /KYC Policies in accordance with changes in legislation and regulatory requirements, as well as new methods and schemes of fraud and money laundering.
        <br/>
        <br/>
        11. Implementation of technological solutions  may use technology solutions such as transaction monitoring systems and data analysis software to improve the effectiveness of the fight against fraud, money laundering, and terrorist financing.
        <br/>
        <br/>
        12. Cooperation with law enforcement agencies Cross may, if necessary, cooperate with law enforcement authorities, and provide them with the necessary information and documentation when investigating suspicious transactions.
        <br/>
        <br/>
        13. Saving records Cross maintains a record of all customer-related transactions and documents for a reasonable period of time to allow for audit and investigation if necessary.
        </div>
    )
}
export default AmlKycPolicy