import { useContext, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import { Link } from "react-router-dom";
import "./login.css";
import LoginFerIcon from '../../assets/login_fer.jpg';

const Login = () => {
  const { loginUser } = useContext(AuthContext);
  const [passwordShow, setPasswordShow] = useState(false);
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formValue.email || !formValue.password) {
      console.log("Enter required fields.");
      return;
    };
    loginUser(formValue);
  };

  const { email, password } = formValue;

  const togglePassword = () => {
    setPasswordShow(!passwordShow);
  }

  return (
    <main className="page page-wrapper">
      <section className="login_page">
        <div className="login_page__container">
          <div className="login_page__wrapper">
            <div className="login_top">
              <div className="login_title">Log In</div>
              <div className="login_text">
                We need you to help nformation to create your account.
              </div>
            </div>
            <form 
              action="#" 
              onSubmit={handleSubmit}
              className="login_form"
              >
              <label htmlFor="#">
                Username
                <input
                  name="email" 
                  type="email"
                  id="email"
                  autoComplete='off'
                  placeholder='Email'
                  value={email}
                  onChange={handleChange}
                  required 
                  
                  />
              </label>

              <label htmlFor="#">
                Password
                <input
                  className="input_pass"
                  name="password" 
                  type={ passwordShow ? "text" : "password"}
                  autoComplete="off"
                  placeholder="Password"
                  value={password}
                  onChange={handleChange}
                  required 
                  

                  />
                <div className="sign_btn_show" onClick={togglePassword}>
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00065 0.625C4.08398 0.625 1.59315 2.43917 0.583984 5C1.59315 7.56083 4.08398 9.375 7.00065 9.375C9.91732 9.375 12.4082 7.56083 13.4173 5C12.4082 2.43917 9.91732 0.625 7.00065 0.625ZM7.00065 7.91667C5.39065 7.91667 4.08398 6.61 4.08398 5C4.08398 3.39 5.39065 2.08333 7.00065 2.08333C8.61065 2.08333 9.91732 3.39 9.91732 5C9.91732 6.61 8.61065 7.91667 7.00065 7.91667ZM7.00065 3.25C6.03232 3.25 5.25065 4.03167 5.25065 5C5.25065 5.96833 6.03232 6.75 7.00065 6.75C7.96898 6.75 8.75065 5.96833 8.75065 5C8.75065 4.03167 7.96898 3.25 7.00065 3.25Z"
                      fill="#808792"
                    />
                  </svg>
                </div>
              </label>
              <img src={LoginFerIcon} alt="Click to verify" />
              <button 
                type="submit" 
                className="btn btn-green btn_login"
                >
                Log In
              </button>
            </form>
            <Link to="/reset-password" className="login_forget">
              Forget password?
            </Link>
            <div className="login_other">
              <span>or</span>
              <div className="login_other__items">
                <Link to="#" className="login_btn_other">
                  Continue with facebook
                </Link>
                <Link to="#" className="login_btn_other">
                  Continue with Google
                </Link>
              </div>
            </div>
            <div className="login_create">
              Don’t have an account? <Link to="/register">Create an account</Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
