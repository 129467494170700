import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";

import Blog from "./components/Blog/Blog";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Casino from "./components/Casino/Casino";
import LiveCasino from "./components/LiveCasino/LiveCasino";
import Promo from "./components/Promo/Promo";
import History from "./components/History/History";
import Referals from "./components/Referals/Referals";
import Deposit from "./components/Deposit/Deposit";
import Withdrawal from "./components/Withdraw/Withdraw";
import Profile from "./components/Profile/Profile";
import Reports from "./components/Reports/Reports";
import Support from "./components/Support/Support";
import Touranments from "./components/Touranments/Touranments";
import ResetPassword from "./components/Auth/ResetPassword";
import SupportTicket from "./components/Support/SupportTicket";
import Game from "./components/Game/Game";
import Dashboard from "./components/Dashboard/Dashboard";
import TwoFactorAuth from "./components/Auth/TwoFactorAuth";
import Single from "./components/Single/Single";
import HeaderNoLogin from "./components/Header/HeaderNoLogin";
import Tvbet from "./components/LiveCasino/Tvbet";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import ResponsibleGaming from "./components/ResponsibleGaming/ResponsibleGaming";
import FAQ from "./components/FAQ/FAQ";
import AmlKycPolicy from "./components/AMLKYCPolicy/AmlKycPolicy";
import RefundPolicy from "./components/RefundPolicy/RefundPolicy";
import PageNotFound from "./pages/PageNotFound";
import AuthContext from "./context/AuthContext";
import FastGames from "./components/FastGames/FastGames";
import VerifyEmail from "./components/VerifyEmail";

// Scroll To Top
function Wrapper({ children }) {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

const RouterComponent = () => {
  const { user, isAuthenticated, logoutUser } = useContext(AuthContext);

  //const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect( () => {
    console.log(isAuthenticated + ' and User: ' + user )
  }, [isAuthenticated]);

  return (
      <div className="wrapper">
        {/* isAuthenticated ? (<Header />) : (<HeaderNoLogin />) */}
        { isAuthenticated === true ? (<Header />) : (<HeaderNoLogin />)  }
          <Wrapper>
            <Routes>
              <Route exact path="/" element={<Casino />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="verify/:id" element={<VerifyEmail />} />
              <Route path="reset-password" element={<ResetPassword />} />

              <Route path="casino" element={<Casino />} />
              <Route path="live-casino" element={<LiveCasino />} />
              <Route path="tvbet" element={<Tvbet />} />
              <Route path="fast-games" element={<FastGames />} />

              <Route path="profile" element={ isAuthenticated === true ? (<Profile />) : (<Navigate to="/login" replace />) } />

              <Route path="profile/history" element={ isAuthenticated === true ? <History /> : <Navigate to="/login" replace /> } />

              <Route path="profile/referals" element={ isAuthenticated === true ? <Referals /> : <Navigate to="/login" replace /> } />

              <Route path="profile/reports" element={ isAuthenticated === true ? <Reports /> : <Navigate to="/login" replace /> } />
              <Route path="profile/twofactorauth" element={ isAuthenticated === true ? <TwoFactorAuth /> : <Navigate to="login" replace /> } />

              <Route path="deposit" element={ isAuthenticated === true ? (<Deposit />) : (<Navigate to="/login" replace />) } />
              <Route path="withdrawal" element={ isAuthenticated === true ? <Withdrawal /> : <Navigate to="/login" replace /> } />

              <Route path="support" element={ isAuthenticated === true ? <Support /> : <Navigate to="/login" replace /> } />
              <Route path="support-ticket" element={ isAuthenticated === true ? <SupportTicket /> : <Navigate to="/login" replace /> } />
              <Route path="game" element={<Game />} />
              <Route path="dashboard" element={ isAuthenticated === true ? (<Dashboard />) : (<Navigate to="/login" replace />) } />

              <Route path="single" element={<Single />} />
              <Route path="tournaments" element={<Touranments />} />
              <Route path="promotion" element={<Promo />} />
              <Route path="blog" element={<Blog />} />

              {/* About Us Footer */}
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="responsible-gaming" element={<ResponsibleGaming />} />

              {/* Help Footer */}
              <Route path="faq" element={<FAQ />} />
              <Route path="aml-kyc-policy" element={<AmlKycPolicy />} />
              <Route path="refund-policy" element={<RefundPolicy />} />

              {/* Our Products Footer */}

              {/* Contact Us Footer */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Wrapper>
        <Footer />
      </div>
  );
};

export default RouterComponent;
