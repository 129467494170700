import './refundPolicy.css';

const RefundPolicy = () => {
    return (
        <div className="refund-wrapper">
            <h2>The Refund Policy of Cross is outlined below:</h2>
            
            <br/>
            <br/>
            1. After using Cross's alleged deposit (including the bonus), refunds cannot be processed
            <br/>
            <br/>
            2. Refund requests must be made within the first 24 hours of the alleged transaction or within 30 days if a player claims that another individual (or a minor) has accessed their Player Account. These requests should be sent via the following link  link. 
            <br/>
            <br/>
            3. We retain the right to delay any refund or reverse transaction until we are satisfied with the identity of the Player Account User, ensuring that any payment made to us will be honored after a refund has been processed. If we request it, you must provide notarized identification or any other certified identification as per the laws of your jurisdiction. Failure to provide such identification within five (5) days of our request will result in the closure of your Player Account, forfeiture of all funds in your Player Account, and a final, binding decision that cannot be appealed.
            <br/>
            <br/>
            4. Players must play games fairly and must not attempt to influence the outcome in any way. This includes using computer aids, mathematical equations, betting systems, and so on.
            <br/>
            <br/>
            5. Cross Gaming will review refund requests, and players will receive a response within five working days after the funds have been reviewed and restored.
        </div>
    )
}
export default RefundPolicy