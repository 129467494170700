import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { domain } from "../../config";


function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/homepage", false, /\.(png|jpe?g|svg|webp)$/)
);

const FastGames = () => {
    const [games, setGames] = useState(null);

  useEffect(() => {
    fetch(`${domain()}/aggregator/games?provider=fiablegames&gameMode=demo&partnerId=1000&language=en&token=`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => response.json())
    //.then(data => console.log(data.data.result) )
    .then(data => setGames(data.data.result.game_list))
    .catch(error => console.log(error))

  }, []);

  return (
    <section className="slots">
      <div className="slots__container">
        <div className="slots_top">
          <div className="title_h2 slots__title">
            <img
              loading="lazy"
              src={images["slots.svg"]}
              alt="Our Awesome Games"
            />{" "}
            Our Awesome Fast Games
          </div>
          <div className="slots__descr">
            In the gaming hall, you have access to crash games, with the highest
            return percentage and incredibly gambling gameplay!
          </div>
        </div>

        <div className="slots_bottom">
            {
                games && games.map( (item, index) => {
                    return(
                        <Link to={item.url} className="slots_item" key={index}>
                            <img 
                                loading="lazy" 
                                src={`https://static.fiable.games/images/fiable/${item.image_name}`}  
                                alt={item.name} 
                                />
                        </Link>
                    )
                }) 
            }
           

          {/*<Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_1.png"]} alt="slot" />
          </Link>

          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_2.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_3.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_4.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_5.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_6.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_7.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_8.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_9.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_1.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_2.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_3.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_4.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_5.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_6.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_7.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_8.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_9.png"]} alt="slot" />
          </Link>*/}

        </div>
        <Link to="/" className="slots_btn btn-green btn">
          More games
        </Link>
      </div>
    </section>
  );
};
export default FastGames;
