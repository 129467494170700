import { Link } from 'react-router-dom';
import './touranments.css';
import HeroTournaments from './HeroTournaments';

const Touranments = () => {
  return (
    <main className="page page-wrapper">
            <HeroTournaments />

            <section className="touranments">
                <div className="touranments__container">
                    <div className="touranments_title title_h2">
                        Weekly Tournaments
                    </div>
                    <div className="touranments_content">
                        In the gaming hall, you have access to slot machines, with the highest return percentage and
                        incredibly gambling gameplay!
                    </div>
                    <div className="touranments_items">
                        <div className="touranments_item">
                            <div className="touranments_item_top dis-f">
                                <div className="touranments_left">
                                    <img loading="lazy" src="./img/homepage/hero_item_1.png" alt="Pragmatic April Hunt" />
                                    <div className="touranments_left__content">
                                        <div className="touranments_left__title">
                                            Pragmatic April Hunt
                                        </div>
                                        <div className="touranments_left__desrc">
                                            Players will join automatically from the moment they place a bet on one
                                            of
                                            the eligible games. There is no registration fee and the registration is
                                            open until the end of the tournament.
                                        </div>
                                        <a href="#" className="touranments_left__btn btn btn-pink">JOIN NOW</a>
                                    </div>
                                </div>
                                <div className="touranments_right">
                                    <div className="touranments_right__top">
                                        <div className="touranments_right__prize">
                                            2,128,948.18
                                        </div>
                                        <div className="touranments_right__end">
                                            Ends in
                                        </div>
                                        <div className="countdown show touranments_right__timer"
                                            data-Date='2023/06/10 09:37:53'>
                                            <div className="running">
                                                <timer>
                                                    <div className="tournament_grid_item">
                                                        <span className="days"></span> <span className="labels">Days</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="hours"></span> <span className="labels">Hour</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="minutes"></span> <span className="labels">Min</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="seconds"></span><span className="labels">Sec</span>
                                                    </div>
                                                </timer>
                                            </div>
                                            <div className="ended">
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Days</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Hour</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Min</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Sec</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_right__content">
                                        <p>Players need to play the eligible games with real money during the promotion
                                            period to qualify for the Tournament. The minimum bet amount for this
                                            tournament is shown on this page above. Any bets of less than the amount
                                            shown will not be counted as a point. The tournament’s win criterion is
                                            "turnover – the higher is the turnover, the more you score”.</p>
                                        <p>Players need to play the eligible games with real money The minimum bet
                                            amount for this tournament is shown on this page above. Any bets of less
                                            than the amount shown will not be counted as a point. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="touranments_bottom">
                                <div className="touranments_bottom__left">
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Registration dates
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Start date:</span> <span className="green">26.04.2023, 11:00</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>End date:</span> <span className="red">30.04.2023, 23:59</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Tournament duration
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Start date:</span> <span className="green">27.04.2023, 00:00</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>End date:</span> <span className="red">30.04.2023, 23:59</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="touranments_bottom__right">
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Rounds
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Minimum</span> <span>1</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>Maximum:</span> <span>∞</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Players</span> <span>1,300</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>Buy in + Fee</span> <span className="green">$1,500</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="touranments_item">
                            <div className="touranments_item_top dis-f">
                                <div className="touranments_left">
                                    <img loading="lazy" src="./img/homepage/hero_item_1.png" alt="Pragmatic April Hunt" />
                                    <div className="touranments_left__content">
                                        <div className="touranments_left__title">
                                            Pragmatic April Hunt
                                        </div>
                                        <div className="touranments_left__desrc">
                                            Players will join automatically from the moment they place a bet on one
                                            of
                                            the eligible games. There is no registration fee and the registration is
                                            open until the end of the tournament.
                                        </div>
                                        <a href="#" className="touranments_left__btn btn btn-pink">JOIN NOW</a>
                                    </div>
                                </div>
                                <div className="touranments_right">
                                    <div className="touranments_right__top">
                                        <div className="touranments_right__prize">
                                            2,128,948.18
                                        </div>
                                        <div className="touranments_right__end">
                                            Ends in
                                        </div>
                                        <div className="countdown show touranments_right__timer"
                                            data-Date='2023/06/10 09:37:53'>
                                            <div className="running">
                                                <timer>
                                                    <div className="tournament_grid_item">
                                                        <span className="days"></span> <span className="labels">Days</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="hours"></span> <span className="labels">Hour</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="minutes"></span> <span className="labels">Min</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="seconds"></span><span className="labels">Sec</span>
                                                    </div>
                                                </timer>
                                            </div>
                                            <div className="ended">
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Days</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Hour</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Min</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Sec</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_right__content">
                                        <p>Players need to play the eligible games with real money during the promotion
                                            period to qualify for the Tournament. The minimum bet amount for this
                                            tournament is shown on this page above. Any bets of less than the amount
                                            shown will not be counted as a point. The tournament’s win criterion is
                                            "turnover – the higher is the turnover, the more you score”.</p>
                                        <p>Players need to play the eligible games with real money The minimum bet
                                            amount for this tournament is shown on this page above. Any bets of less
                                            than the amount shown will not be counted as a point. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="touranments_bottom">
                                <div className="touranments_bottom__left">
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Registration dates
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Start date:</span> <span className="green">26.04.2023, 11:00</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>End date:</span> <span className="red">30.04.2023, 23:59</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Tournament duration
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Start date:</span> <span className="green">27.04.2023, 00:00</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>End date:</span> <span className="red">30.04.2023, 23:59</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="touranments_bottom__right">
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Rounds
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Minimum</span> <span>1</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>Maximum:</span> <span>∞</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Players</span> <span>1,300</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>Buy in + Fee</span> <span className="green">$1,500</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="touranments_item">
                            <div className="touranments_item_top dis-f">
                                <div className="touranments_left">
                                    <img loading="lazy" src="./img/homepage/hero_item_1.png" alt="Pragmatic April Hunt" />
                                    <div className="touranments_left__content">
                                        <div className="touranments_left__title">
                                            Pragmatic April Hunt
                                        </div>
                                        <div className="touranments_left__desrc">
                                            Players will join automatically from the moment they place a bet on one
                                            of
                                            the eligible games. There is no registration fee and the registration is
                                            open until the end of the tournament.
                                        </div>
                                        <a href="#" className="touranments_left__btn btn btn-pink">JOIN NOW</a>
                                    </div>
                                </div>
                                <div className="touranments_right">
                                    <div className="touranments_right__top">
                                        <div className="touranments_right__prize">
                                            2,128,948.18
                                        </div>
                                        <div className="touranments_right__end">
                                            Ends in
                                        </div>
                                        <div className="countdown show touranments_right__timer"
                                            data-Date='2023/06/10 09:37:53'>
                                            <div className="running">
                                                <timer>
                                                    <div className="tournament_grid_item">
                                                        <span className="days"></span> <span className="labels">Days</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="hours"></span> <span className="labels">Hour</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="minutes"></span> <span className="labels">Min</span>
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="tournament_grid_item">
                                                        <span className="seconds"></span><span className="labels">Sec</span>
                                                    </div>
                                                </timer>
                                            </div>
                                            <div className="ended">
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Days</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Hour</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Min</span>
                                                </div>
                                                <div className="separator">:</div>
                                                <div className="tournament_grid_item">
                                                    <span className="end_time">00</span> <span className="labels">Sec</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_right__content">
                                        <p>Players need to play the eligible games with real money during the promotion
                                            period to qualify for the Tournament. The minimum bet amount for this
                                            tournament is shown on this page above. Any bets of less than the amount
                                            shown will not be counted as a point. The tournament’s win criterion is
                                            "turnover – the higher is the turnover, the more you score”.</p>
                                        <p>Players need to play the eligible games with real money The minimum bet
                                            amount for this tournament is shown on this page above. Any bets of less
                                            than the amount shown will not be counted as a point. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="touranments_bottom">
                                <div className="touranments_bottom__left">
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Registration dates
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Start date:</span> <span className="green">26.04.2023, 11:00</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>End date:</span> <span className="red">30.04.2023, 23:59</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Tournament duration
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Start date:</span> <span className="green">27.04.2023, 00:00</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>End date:</span> <span className="red">30.04.2023, 23:59</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="touranments_bottom__right">
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__title">
                                            Rounds
                                        </div>
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Minimum</span> <span>1</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>Maximum:</span> <span>∞</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="touranments_bottom__item">
                                        <div className="touranments_bottom__table">
                                            <div className="touranments_bottom__col">
                                                <span>Players</span> <span>1,300</span>
                                            </div>
                                            <div className="touranments_bottom__col">
                                                <span>Buy in + Fee</span> <span className="green">$1,500</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
  );
};
export default Touranments;
