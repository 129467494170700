import { Link } from "react-router-dom";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/homepage", false, /\.(png|jpe?g|svg|webp)$/)
);

const Slots = () => {
  return (
    <section className="slots">
      <div className="slots__container">
        <div className="slots_top">
          <div className="title_h2 slots__title">
            <img
              loading="lazy"
              src={images["slots.svg"]}
              alt="Our Awesome Games"
            />{" "}
            Our Awesome Games
          </div>
          <div className="slots__descr">
            In the gaming hall, you have access to slot machines, with the
            highest return percentage and incredibly gambling gameplay!
          </div>
        </div>

        <div className="slots_bottom">
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_1.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_2.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_3.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_4.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_5.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_6.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_7.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_8.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_9.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_1.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_2.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_3.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_4.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_5.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_6.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_7.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_8.png"]} alt="slot" />
          </Link>
          <Link to="/" className="slots_item">
            <img loading="lazy" src={images["slot_9.png"]} alt="slot" />
          </Link>
        </div>

        <Link to="/" className="slots_btn btn-green btn">
          More games
        </Link>
        
      </div>
    </section>
  );
};
export default Slots;
