import './game.css'; 
import JacpotsUserImage from '../../assets/homepage/jacpots_user_image.png';
import GameImage from '../../assets/game/game.png';

const Game = (props) => {
  return (
    <main className="page page-wrapper">
    <section className="game">
        <div className="game__container">
            <div className="game_wrapper">
                <div className="game_top">
                    <div className="game_content">
                        <div className="game__title">
                            Biggest Jackpots
                        </div>
                        <div className="game_setings">
                            <div className="game_seting game_favourite active">
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21 6.78285C21 14.4409 11.2697 20.6396 10.8553 20.8956C10.7461 20.9641 10.624 21 10.5 21C10.376 21 10.2539 20.9641 10.1447 20.8956C9.73031 20.6396 0 14.4409 0 6.78285C0.00173694 4.98455 0.614681 3.26048 1.70436 1.98889C2.79404 0.717297 4.27146 0.00202691 5.8125 0C7.74844 0 9.44344 0.971479 10.5 2.61358C11.5566 0.971479 13.2516 0 15.1875 0C16.7285 0.00202691 18.206 0.717297 19.2956 1.98889C20.3853 3.26048 20.9983 4.98455 21 6.78285Z"
                                        fill="none" />
                                </svg>

                                <span>Add to favorites</span>
                            </div>
                            <div className="game_seting game_refresh">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.65 6.34989C16.8118 5.50416 15.7944 4.8575 14.6728 4.45768C13.5512 4.05786 12.3542 3.91508 11.17 4.03989C7.50002 4.40989 4.48002 7.38989 4.07002 11.0599C3.52002 15.9099 7.27002 19.9999 12 19.9999C13.5101 19.9999 14.9892 19.5714 16.2655 18.7642C17.5418 17.957 18.5628 16.8043 19.21 15.4399C19.53 14.7699 19.05 13.9999 18.31 13.9999C17.94 13.9999 17.59 14.1999 17.43 14.5299C16.8494 15.7789 15.8564 16.7899 14.6179 17.3927C13.3795 17.9955 11.9712 18.1534 10.63 17.8399C8.41002 17.3499 6.62002 15.5399 6.15002 13.3199C5.95245 12.4422 5.95474 11.5312 6.15672 10.6545C6.35869 9.77775 6.75519 8.95764 7.31687 8.25481C7.87856 7.55198 8.59107 6.98442 9.40169 6.59411C10.2123 6.20379 11.1003 6.00071 12 5.99989C13.66 5.99989 15.14 6.68989 16.22 7.77989L14.71 9.28989C14.08 9.91989 14.52 10.9999 15.41 10.9999H19C19.55 10.9999 20 10.5499 20 9.99989V6.40989C20 5.51989 18.92 5.06989 18.29 5.69989L17.65 6.34989Z"
                                        fill="none" />
                                </svg>
                                <span>Refresh</span>
                            </div>
                            <div className="game_seting game_fs">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6 14C5.45 14 5 14.45 5 15V18C5 18.55 5.45 19 6 19H9C9.55 19 10 18.55 10 18C10 17.45 9.55 17 9 17H7V15C7 14.45 6.55 14 6 14ZM6 10C6.55 10 7 9.55 7 9V7H9C9.55 7 10 6.55 10 6C10 5.45 9.55 5 9 5H6C5.45 5 5 5.45 5 6V9C5 9.55 5.45 10 6 10ZM17 17H15C14.45 17 14 17.45 14 18C14 18.55 14.45 19 15 19H18C18.55 19 19 18.55 19 18V15C19 14.45 18.55 14 18 14C17.45 14 17 14.45 17 15V17ZM14 6C14 6.55 14.45 7 15 7H17V9C17 9.55 17.45 10 18 10C18.55 10 19 9.55 19 9V6C19 5.45 18.55 5 18 5H15C14.45 5 14 5.45 14 6Z"
                                        fill="none" />
                                </svg>


                                <span>Open Full Screen</span>
                            </div>
                            <div className="game_seting game_close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                                        fill="none" />
                                </svg>
                                <span>Close</span>
                            </div>
                        </div>
                    </div>

                    <div className="game__items swiper">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="game__item">
                                    <div className="slots_item__top">
                                        <img src={JacpotsUserImage} alt="User" />
                                        <p>Won by <span>ale***</span></p>
                                    </div>
                                    <div className="slots_item__center">
                                        <p>AMD <span>720,500.44</span></p>
                                    </div>
                                    <div className="slots_item__bottom">
                                        10 minutes ago
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="game_bottom">
                <img src={GameImage} alt="Game Iframe" />
            </div>
        </div>
    </section>
</main>
  );
};
export default Game;
