import "./reports.css";
import Transactions from "./Transactions";
import Commission from "./Commission";
import GameLog from "./GameLog";

const Reports = () => {
  return (
    <main className="page page-wrapper">
      <Transactions />
      <Commission />
      <GameLog />
    </main>
  );
};
export default Reports;
