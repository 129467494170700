import './responsibleGaming.css';

const ResponsibleGaming = () => {
    return (
        <div className='responsible-wrapper'>
            Responsible Gaming Policy
            <br/>
            <br/>

            Introduction
            <br/>
            At crossgaming.com, we are committed to providing our customers with a safe and enjoyable gaming experience. We believe in responsible gaming and are dedicated to protecting the well-being of our customers. This responsible gaming policy outlines our commitment to ensuring that gaming remains a form of entertainment rather than a problem.
            <br/>
            <br/>
            1. Age Verification
            <br/>
            We strictly enforce a minimum age requirement for our customers. Players must be at least 18 years old to participate in our games. We conduct age verification checks during the registration process to ensure compliance with this policy.
            <br/>
            2. Self-Exclusion Options
            <br/>
            We offer self-exclusion options for customers who wish to take a break from gambling. Customers can request to be excluded from our platform for a specific period, and we will ensure they are unable to access our games during that time.
            <br/>
            3. Deposit Limits
            <br/>
            Customers have the option to set daily, weekly, or monthly deposit limits on their accounts. We encourage all players to use this feature to manage their spending responsibly.
            <br/>
            4. Responsible Gaming Information
            <br/>
            We provide information and resources related to responsible gaming prominently on our website. This includes information on recognizing and addressing problem gambling behaviors, as well as contact details for organizations that offer support and assistance.
            <br/>
            5. Customer Support
            <br/>
            Our customer support team is trained to assist customers with questions or concerns related to responsible gaming. If you ever feel the need to discuss your gaming habits or seek guidance, please don't hesitate to contact us.
            <br/>
            6. Responsible Advertising and Marketing
            <br/>
            We are committed to responsible advertising and marketing practices. Our promotional materials will not target minors or encourage excessive gambling. We will also avoid using misleading or deceptive advertising.
            <br/>
            7. Collaboration with Responsible Gambling Organizations
            <br/>
            crossgaming.com collaborates with reputable organizations dedicated to responsible gaming. We support initiatives that promote awareness, prevention, and treatment of problem gambling.
            <br/>
            8. Monitoring and Reporting
            <br/>
            We actively monitor customer behavior for signs of problem gambling and take appropriate action when necessary. We also encourage customers to report concerns about their own gambling habits or the habits of others.
            <br/>
            9. Legal Compliance
            <br/>
            We operate in compliance with all relevant local and international laws and regulations related to online gambling. We maintain licenses and adhere to strict regulatory requirements.
            Conclusion
            <br/>
            At crossgaming.com, we understand the importance of responsible gaming, and we take this commitment seriously. We are dedicated to providing a safe and enjoyable gaming environment for our customers while ensuring that problem gambling is prevented and addressed. If you have any questions, concerns, or require assistance, please don't hesitate to reach out to our customer support team.
            <br/>
            Remember, gambling should always be an enjoyable and controlled form of entertainment, and we are here to help ensure that it remains that way.
        </div>
    )
}
export default ResponsibleGaming;