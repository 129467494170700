import './privacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-wrapper'>
            <h3>User Agreement</h3>
            <br/>

            <b>Definitions</b>
            <br/>
            Cross Gaming is referred to as 'we' or 'us'
            <br/>
            The Player is referred to as "you" or 'the Player'
            <br/>
            'The Website' means Cross Gaming through desktop, mobile or other platforms utilized by the Player

            <br/>
            <br/>
            1. General
            <br/>
            1.1. The use of games accessed through Cross Gaming is subject to this User Agreement.
            <br/>
            1.2. As soon as you finish the registration procedure, which includes checking the box indicating that you accept this User Agreement and successfully create an account, this User Agreement is put into effect. Upon account creation, you agree to this User Agreement by using any portion of the Website.
            <br/>
            1.3. You must carefully read this User Agreement before establishing an account. You must not register for an account or use the Website if you disagree with any of the terms of this User Agreement.
            <br/>
            1.4. This User Agreement is subject to change at any moment and without prior notification. You will be responsible for checking for any updates, modifications, and/or amendments. If we make such amendments, we may take appropriate steps to notify you of such changes (such as by email or placing a notice on a prominent position on the Website, along with the amended User Agreement). Upon any such revision to the User Agreement, your continuing use of the Cross Gaming services and website will be taken as your acceptance and consent to be bound by such amendments, updates, and/or modifications.
            <br/>
            1.5. This User Agreement may be published in many languages to facilitate player access and for informational purposes. The connection between you and us is only legally based on the English version, and in the event of any inconsistency concerning any translation, the English version of this User Agreement shall control.
            <br/>
            <br/>
            2. Binding Declarations
            <br/>
            2.1. By accepting this User Agreement, you also accept the Cross Gaming Rules and Privacy Policy, which are thus included in this User Agreement by reference. This User Agreement will take precedence if there is any conflict. You, therefore, affirm and guarantee that:
            <br/>
            2.1.1. You are older than (a) the age of 18 or (b) the age of majority as defined by any relevant legislation.
            <br/>
            2.1.2. You are fully capable of entering into a contract with us and are not subject to any limitations based on your legal competence.
            <br/>
            2.1.3. You represent and warrant that any information you give to us is true, accurate, current, and complete and that you will notify us promptly of any changes to such information.
            <br/>
            2.1.4. For any winnings you obtain from us, it is your exclusive responsibility to record and pay for any taxes that may apply to you under applicable legislation.
            <br/>
            2.1.5. You acknowledge that by using our services, you assume the risk of losing money put into your Member Account and accept full responsibility for any resulting loss.
            <br/>
            2.1.6 You are allowed to use online casino services in the country or region where you reside.
            <br/>
            2.1.7. You may only use Cryptocurrency that is legitimate and rightfully yours for making deposits or withdrawals into or from your Member Account.
            <br/>
            2.1.8. You are aware that cryptocurrency values fluctuate significantly based on market conditions.
            <br/>
            2.1.9 The websites, user interface, computer software, and computer graphics we provide for you are owned by Cross Gaming or its affiliates and covered by copyright laws. You may only make personal, recreational use of the program in line with the User Agreement we have set and all relevant laws, rules, and regulations.
            <br/>
            2.1.10 You know that cryptocurrencies are viewed as virtual money on the website with no inherent value because they are not regarded as legal tender or currencies.
            <br/>
            2.1.11 You certify that you are not an official, director, employee, consultant, or agent of Cross Gaming, nor are you married to or connected to any of those as mentioned above, nor are you a compulsive or problem gambler.
            <br/>
            2.1.12 You also certify that you are not working for any company affiliated with Cross Gaming. If such problem gambling occurs while using our services, we are not liable, but we will try to advise you of the appropriate available help. We reserve the right to enact cooling-off periods if doing so will be advantageous.
            <br/>
            2.1.13. You agree and understand that we retain the right to identify and stop the use of prohibited techniques, including but not limited to automated registration and signup, gaming, screen-capture methods, and fraudulent transaction detection. These actions could entail, but are not limited to: checking the player's device's attributes, looking for geolocation and IP masking, looking at transactions, and analyzing blockchains.
            <br/>
            2.1.14. You agree that we can stop offering games or events on the website, alter them, or restrict (or deny) bets.
            <br/>
            2.1.15. You acknowledge that we can ban/block numerous accounts and freely handle the funds contained in such accounts.
            <br/>
            2.1.16. You acknowledge that there may be bugs or incomplete parts of the program and agree not to exploit them. Also, you consent to notify Cross Gaming immediately of any mistake or incompleteness. If you don't follow through on your duties under this clause, Cross Gaming has the right to full reimbursement for all costs associated with the error or incompleteness, including any expenses linked to the specific fault/incompleteness and the user's failure to notice.
            <br/>
            2.1.17. You know that Cross Gaming may conduct KYC (Know Your Customer) verification processes. If we discover that you provided inaccurate or deceptive information, access to your user account may be restricted or terminated.
            <br/>
            2.2. We retain the right to deem a wager void in whole or in part if Cross Gaming determines, in its sole discretion, that any of the following has occurred:
            <br/>
            2.2.1 You directly or indirectly affect an event's outcome to gain an unfair advantage.
            <br/>
            2.2.2 You and/or others connected to you somehow obstruct Cross Gaming's regulations.
            <br/>
            2.2.3 The outcome of an event has been impacted by illegal action either directly or indirectly.
            <br/>
            2.2.4 Bets have been made that would not have been honored ordinarily but that were honored when the website was experiencing technical difficulties.
            <br/>
            2.2.5 Bets have been offered, placed, and/or accepted owing to an error, such as a mistake, technological error, force majeure, or other error.
            <br/>
            2.2.6 If a player's deposit fee is too low and is reported as "not enough fee to relay" by a blockchain or similar website, Cross Gaming retains the right to seize the player's winnings if Cross Gaming determines that the transaction and the player's actions are fraudulent.
            
            <br/>
            <br/>
            3. Restricted Territories
            <br/>
            3.1. Territories that are on the blacklist include China, the Netherlands, the Dutch Caribbean Islands, Hungary, Australia, Ontario (Canada), Curacao, France, the United States, and/or any other nation or territory that is subject to legal restrictions. Please note that playing Cross Gaming games in the specified restricted countries is wholly prohibited. Your personal information to carry out their jobs and give you the best help and service possible. By signing this, you agree to such disclosures.
            
            <br/>
            <br/>
            4. General Betting Rules
            <br/>
            4.1. Only registered account holders are permitted to place bets.
            <br/>
            4.2. A wager may only be placed online.
            <br/>
            4.3 You can only place a bet if you have enough money in your Cross Gaming account to cover it.
            <br/>
            4.4. The User Agreement in effect and posted on the Website when the wager is accepted shall govern the bet once it has been placed.
            <br/>
            4.5. Any successful bet payout, which consists of the amount multiplied by the bet's odds, is credited to your account.
            <br/>
            4.6 If Cross Gaming determines, in its sole discretion, that a payout has been deposited to a Cross Gaming account by mistake, it has the right to alter the payout.
            <br/>
            4.7. Once a wager has been placed and accepted, you cannot change, cancel, or withdraw it.
            <br/>
            4.8. You may use the Website to get a list of all the bets, their status, and information.
            <br/>
            4.9. By placing a bet, you confirm that you have read and fully understand this User Agreement as posted on the Website.
            <br/>
            4.10. Your account is managed by Cross Gaming, which also determines the available, pending, betting, and winning amounts. These sums are regarded as final and are believed to be correct unless proven differently.
            <br/>
            4.11 The wagers you make are your sole responsibility.
            <br/>
            4.12 Winnings will be deposited into your account upon verifying the outcome.
            
            <br/>
            <br/>
            5. Bonuses and Promotions
            <br/>
            5.1. If Cross Gaming believes the bonus has been set up incorrectly or is being abused, we reserve the right to decline any withdrawal requests and to deduct the amount from your account. We also reserve the right to cancel any promotion or bonus program (including, but not limited to, top-up rewards and invite friends to reward prizes and loyalty programs) with immediate effect. Cross Gaming alone will decide whether a bonus is regarded to have been exploited or set up improperly.
            <br/>
            5.2. If you utilize a deposit bonus, your initial amount can only be withdrawn once you have fulfilled the conditions outlined in the deposit bonus's user agreement.
            <br/>
            5.3. If a condition of the offer or promotion is broken or there is proof of a series of bets placed by a customer or group of customers that result in guaranteed customer profits regardless of the outcome due to a deposit bonus, enhanced payments, free bets, risk-free bets, or any other promotional offer, Cross Gaming reserves the right to reclaim the bonus component of such request. We may settle bets at the correct odds, cancel the free bet bonus and risk-free bets, or void any wagers financed by the deposit bonus at our sole discretion. In counterpart to that, Cross Gaming retains the right to impose a fee to the user for administrative costs up to the amount of any deposit bonuses, free bet bonuses, risk-free bets, or other payments. In addition, we retain the right to require any client, before we credit their account with any bonuses, free bets, risk-free bets, or offers, to submit adequate verification to satisfy us, in our sole discretion, of the customer's identification.
            <br/>
            5.4. All Cross Gaming promotions are meant for casual gamers, and Cross Gaming has the right to restrict customer eligibility for all or a portion of any campaign.
            <br/>
            5.5. Cross Gaming retains the right, in its sole discretion, to modify, revoke, reclaim, or reject any promotion.
            <br/>
            5.6. Bonuses are only valid for one recipient per person/account, family, home, address, email address, IP address, and shared computer environment (university, fraternity, school, public library, workplace). If the Operator discovers evidence of misuse or fraud, they have the right to terminate your account and seize any money that is already in it.
            <br/>
            5.7. You realize and agree that in addition to this User Agreement, other User Agreements apply to promotions, bonuses, and special offers. This User Agreement is described on the relevant content page on this website or, if applicable, has been made accessible to you directly. Where the terms of such promotions, bonuses, and special offers conflict with those of this User Agreement, the terms of such promotions, bonuses, and special offers shall take precedence.
            <br/>
            5.8. Before using any free or bonus money we credit to your account, we may require that you wager a particular amount of your deposit.
            <br/>
            5.9 You acknowledge that some promotions may have withdrawal limits and/or conditions that must be satisfied before cash credited as part of the promotion can be withdrawn. These conditions must be adequately disclosed and made accessible as part of the offer. We shall remove the whole bonus amount, and any wins associated with using the bonus amounts before allowing any withdrawals if you choose to make one before the appropriate wagering requirements are satisfied.

            <br/>
            <br/>
            6. Live Chat
            <br/>
            6.1. As part of using the website, we give you access to a live chat feature that is regulated by us and governed by specific rules. We retain the right to examine the discussion and log all comments posted on the platform. You should only use the chat feature for socializing and leisure activities.
            <br/>
            6.2. If any of the following applies, we reserve the right to suspend the chat room feature or immediately cancel your Member Account and issue a refund to your account balance:
            <br/>
            (a) You make comments that are violent, disparaging, insulting, or disrespectful; 
            <br/>
            (b) You make statements that are sexually explicit or grossly offensive, including expressions of bigotry, racism, hatred, or profanity; 
            <br/>
            (c) You make statements about Cross Gaming or any other Internet site(s) connected to the Website, that are misleading, hateful, or harmful to Cross Gaming;
            <br/>
            (d) You use the chat feature to promote, advertise, or otherwise interact with any other online entities; 
            <br/>
            (e) You engage in unlawful conduct, or encourage behavior we deem seriously inappropriate. Any suspicious chats will be reported to the competent authority.
            <br/>
            6.3. Live Chat is a method of communication between you and us and is not intended to be reproduced or distributed to other forums or websites.

            <br/>
            <br/>
            7. Limitation of Liability
            <br/>
            7.1. You do so at your own risk when using the Website and participating in the Games. The Websites and the Games are provided with no explicit or implied warranties. 
            <br/>
            7.2. Without limiting the applicability of the preceding clause generally, we, our directors, employees, partners, and service providers.
            <br/>
            7.3. Do not guarantee that the apps, games, or websites are fit for their intended use.
            <br/>
            7.4. Do not guarantee that the games, software, or websites are error-free.
            <br/>
            7.5. Do not guarantee that the software, games, and websites will always be available.
            <br/>
            7.6. Cross Gaming will not be held responsible for any losses, costs, expenses, or damages, whether direct, indirect, special, consequential, incidental, or otherwise, resulting from your use of the Websites or your participation in the Games.
            <br/>
            7.7. You understand and agree that if a Game or its interoperability malfunctions, any bets placed during such a malfunction are invalid. No matter what Games are played with such money, any funds gained from a malfunctioning Game shall be deemed null and worthless, as well as any following game rounds played with such funds.
            <br/>
            7.8. By signing this agreement, you agree to hold us, our directors, employees, partners, and service providers harmless from any costs, expenses, losses, damages, lawsuits, or other liabilities that may result from your use of the Website or participation in the Games, regardless of how those things were brought about.
            <br/>
            7.9 To the fullest extent permissible by law, we shall not be liable for more than €100 in connection with your use of the Websites, regardless of the basis for the claim (contract, tort, warranty breach, or other).

            <br/>
            <br/>
            8. Breaches, Penalties, and Termination
            <br/>
            8.1. If you violate any of the terms of this User Agreement or if we have good reason to believe that you have done so, we reserve the right to refuse to open, suspend, or close your Member Account, withhold payment of your winnings, and use those funds to cover any damages you may be owed.
            <br/>
            8.2 You agree that Cross Gaming will ultimately determine whether your behavior violates Cross Gaming's policies or terms in a way that leads to your suspension or permanent exclusion from our site.

            <br/>
            <br/>
            9. Self-exclusion
            <br/>
            9.1 By asking for a self-exclusion period, you consent to abide by the terms and conditions listed below, which take effect when CS starts the selected self-exclusion period.
            <br/>
            9.2 You can self-exclude permanently or for periods of 1, 3, 6, or 12 months. Requests for self-exclusion must be submitted through Live Help.
            <br/>
            9.3 After self-excluding, you won't be able to access your account or make withdrawals for the next three days.
            <br/>
            9.4 If your account has outstanding bets and you have omitted your account, the bets you placed will still be legitimate and settled following official outcomes.
            <br/>
            9.5. You can withdraw winnings from eligible bets after the self-exclusion period. All bets placed before implementing a self-exclusion are not canceled or voided by Cross Gaming.
            <br/>
            9.6. Until the term you choose for self-exclusion has gone, you won't be able to change the period for a shorter duration or have your self-exclusion canceled after you've self-excluded.
            <br/>
            9.7. If you want to prolong your self-exclusion period, please contact our customer support staff.
            <br/>
            9.8. You can seek account restoration by emailing support once your self-exclusion time has passed.
            <br/>
            9.9. By self-excluding, you accept the following obligations: 
            <br/>
            a) You won't open another account during this time.
            <br/>
            b) You won't deposit money into a Cross Gaming account or make any attempt to do so.
            <br/>
            c) You won't place any bets on our website at this time.
            <br/>
            d) Because this is a voluntary action on your part, Cross Gaming disclaims all responsibility for any losses you might suffer due to the self-exclusion.
            
            <br/>
            <br/>
            Privacy Policy
            <br/>
            You agree that we may, at our discretion, collect and use your personal information to provide you with access to and use the Websites and enable your participation in the Games.
            We thus recognize that the Data Protection Act applies to collect your personal information as described in the preceding clause. In compliance with ethical standards for conducting business and any legal requirements, we will secure your personal information and respect your privacy.
            We shall utilize your personal data to enable you to participate in the Games and conduct activities necessary for your participation. Your personal information may also notify you about updates, new services, and promotions that interest you. You can choose not to use the service if you do not want to receive these direct marketing communications.
            Unless compelled by law, your personal information will not be given to other parties. Personal data may be provided to Cross Gaming business partners, suppliers, or service providers who may be in charge of particular aspects of the Website's general functionality or operation. Your personal information is accessible to Cross Gaming staff members so they can carry out their jobs and give you the best support and service possible. By signing this, you agree to such disclosures.
            We will retain all given information as personal data. You can look up any personal information we may hold on you. No data may be deleted unless required by law or unless it is no longer necessary to keep it for the relationship's purposes.
            We gather a small amount of data transmitted from your browser, known as a cookie, to enhance the usability of your visit to the Websites, to record visits to the Websites, and to better the service. If you'd like, you may disable the cookie collecting. Nevertheless, you should be aware that disabling cookies may significantly limit or prevent you from using the Websites.

            <br/>
            <br/>
            Cookies Policy
            <br/>
            <br/>
            1.	What are cookies?
            <br/>
            A cookie is a bit of data in the form of a tiny text file stored on an internet user's computer. It is produced by a web page server, essentially the machine that runs the website, and may be utilized by that server each time a user accesses it. An identity card for online users and cookies let websites know when a user has visited before. Cookies cannot damage your computer, and none of our cookies include personally identifying information about you.
            <br/>
            <br/>
            2. Why do we use cookies on Cross Gaming?
            <br/>
            Cookies set by us and those set by third parties are the two categories Cross Gaming uses (i.e., other websites or services). Using Cross Gaming cookies, we can maintain your account login during your visit and adjust the website's content to suit your preferences.
            <br/>
            <br/>
            3. What cookies do we use on Cross Gaming?
            <br/>
            Below is a list of the primary cookies set by Cross Gaming and what each is used for:
            <br/>
            _fp - stores browser's fingerprint. Lifetime: forever.
            <br/>
            _t - stores timestamp when the user first visited the site in a current browsing session. Needed for unique visits statistic. Lifetime: browsing session.
            <br/>
            _r - stores http referrer for the current browsing session. Required to external track traffic sources. Lifetime: browsing session.
            <br/>
            _c - stores the identifier of the affiliate campaign. Needed for affiliate statistics. Lifetime: forever.
            <br/>
            Cookies set by third parties for wildcard domain: *.Cross Gaming
            <br/>
            Google Analytics: _ga, _gat, _gid
            <br/>
            Zendesk：__ zlcmid
            <br/>
            Cloudflare：__ cfuid
            <br/>
            Please keep in mind that some browsers (i.e., Chrome) support background processes running even if no tabs opened due to this session. Cookies may leave set between sessions.
            <br/>
            Third-party scripts also set cookies to their domains.
            <br/>
            <br/>
            4. How can I manage my cookies on Cross Gaming?
            <br/>
            You may disable the cookie acceptance in your browser's Privacy Options if you'd like to.
            <br/>
            Personal Data Protection Policy
            <br/>
            Our goal at Cross Gaming is to secure your data, and we take several steps to do so. We provide our clients with rigorous security standards, including data encryption in databases and in motion across public networks, auditing requirements, Distributed Denial of Service mitigations, and an on-site Live Chat.
            <br/>
            Server Protection Policy
            <br/>
            •	All servers have full encryption;
            <br/>
            •	All backups have encryption;
            <br/>
            •	Firewalls, VPN Access;
            <br/>
            •	Access to servers allowed only over VPN;
            <br/>
            •	All http/s services work over Cloudflare;
            <br/>
            •	Connections to nodes over VPN;
            <br/>
            •	SSH port forwarding tunnels;
            <br/>
            •	Services allowed only over VPN;
            <br/>
            •	The server has a firewall and allows only SSH port;
            <br/>
            •	Alerts on critical services.
            <br/>
            •	Data Breach Notification
            <br/>
            In compliance with GDPR timeframes, Cross Gaming will notify the appropriate users when personal data breaches are discovered.
            <br/>
            Data International Transfer
            <br/>
            Only when it is required to deliver a high-quality service or to comply with legal requests from authorities do we divulge personal information to third parties.
            <br/>
            The following information is shared with external systems by us:
            <br/>
            When a user uses live chat or sends an email to the support mailbox, Zendesk Inc. receives their username and email address.
            <br/>
            Despite our best efforts, issues occasionally may arise. Our team will provide a quick solution to your problems. You can write our support team to get help from us more quickly.
            <br/>
            If an error occurs, please provide the following information:
            <br/>
            •	Username
            <br/>
            •	Date and time of the problem
            <br/>
            •	Game ID
            <br/>
            •	Screenshot of the error, if possible
            <br/>
            We appreciate your help and the error report you provided because your information report could help us improve. By utilizing Our Service, We can ask You for personally identifiable information that allows Us to contact or identify you. Among other things, personally identifiable information might be:
            <br/>
            •	Email address
            <br/>
            •	First name and last name
            <br/>
            •	Usage Data
            <br/>
            Usage Data is gathered automatically when using the Service.
            <br/>
            Usage Data may comprise particulars like the Internet Protocol address (IP address) of Your Device, the kind, and version of your browser, the pages of our service that You access, when and how long you spend on those pages, unique device identifiers, and other diagnostic data.
            <br/>
            We may automatically gather certain information when You use a mobile device to access the Service, incorporating, but not limited to, the type of mobile device You use, its unique ID, its IP address, its operating system, the kind of mobile Internet browser you use, special device identifiers, and other diagnostic information.
            <br/>
            Whether you use a mobile device to use our service or when you visit our website, we may additionally get information from your browser.
            <br/>
            Information from Third-Party Social Media Services
            <br/>
            Cross Gaming allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
            •	Google
            •	Facebook
            <br/>
            •	Telegram
            <br/>
            •	Metamask
            <br/>
            •	Web3.0
            
            <br/>
            <br/>
            We may gather Personal information that is already linked to your account on a Third-Party Social Media Service, such as your name, email address, activity, or contact list linked to that account, if you choose to register through that service or give us access to it in any other way.
            <br/>
            Using the account of Your Third-Party Social Media Service, you may also have the choice to share more information with the Cross Gaming. You grant Cross Gaming permission to use, disclose, and keep the news and Personal Data you decide to submit, whether through registration or in another way, following this Privacy Policy.
            <br/>
            Delete Personal Data
            <br/>
            If Cross Gaming no longer needs your personal information to process or keep it, you can request that it be erased. Please be aware that this privilege is not guaranteed, meaning that Cross Gaming may not be able to honor your request if Cross Gaming is required by law to keep your data on file. Sending an email to support your demand to have your personal information deleted is possible.
            <br/>
            Registration and Login
            <br/>
            To register, you must be at least 18 years old. Please double-check your email address if you want to add one so that it may be used to verify your account later on for KYC purposes.
            <br/>
            Logging in is always possible. You should add 2FA for further protection, as we advise, for more information on Google Authenticator.
            <br/>
            We are very sorry, but we cannot update your registered email if you need to alter this information. We advise closing the current account and creating a new one if you insist on changing your username and/or registered email.
        </div>
    )
}
export default PrivacyPolicy;