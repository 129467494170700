

const SupportTicket = () => {
  

  return (
    <main className="page page-wrapper">
      <div className="support_pop">
        <div className="support_wrapper dis-f aic jcc">
          <div className="form_title support_title">Support Ticket</div>
          <p className="support_text form_text">
            Payment methods for the region
          </p>
          <form action="#" className="support_form dis-f">
            <label>
              Name
              <input type="text" placeholder="Name"  />
            </label>
            <label>
              Email address
              <input type="email" placeholder="Email"  />
            </label>
            <label>
              Subject
              <select name="subject" id="subject">
                <option value="">Subject</option>
                <option value="Subject 1">Subject 1</option>
                <option value="Subject 2">Subject 2</option>
                <option value="Subject 3">Subject 3</option>
                <option value="Subject 4">Subject 4</option>
              </select>
            </label>
            <label>
              Priority
              <select name="priority" id="priority">
                <option value="High" selected>
                  High
                </option>
                <option value="Low">Low</option>
              </select>
            </label>
            <label>
              Message
              <textarea
                name="message"
                id="message"
                placeholder="Message"
              ></textarea>
            </label>
            <div className="support_bottom dis-f jcsb aic">
              <div className="support_file">
                <input
                  id="file"
                  type="file"
                  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                  
                />
                <div className="support_fake_btn">
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.783204 6.47518C0.783027 6.5742 0.812255 6.67104 0.867184 6.75343C0.922112 6.83582 1.00027 6.90004 1.09174 6.93796C1.18321 6.97588 1.28389 6.98578 1.38099 6.96642C1.4781 6.94705 1.56727 6.89929 1.6372 6.82918L6.2322 2.23218C6.70111 1.76328 7.33708 1.49985 8.0002 1.49985C8.32855 1.49985 8.65369 1.56452 8.95704 1.69018C9.26039 1.81583 9.53603 2 9.7682 2.23218C10.0004 2.46436 10.1846 2.73999 10.3102 3.04334C10.4359 3.3467 10.5005 3.67183 10.5005 4.00018C10.5005 4.32853 10.4359 4.65366 10.3102 4.95701C10.1846 5.26037 10.0004 5.536 9.7682 5.76818L4.4652 11.0712C4.2766 11.2533 4.024 11.3541 3.7618 11.3519C3.49961 11.3496 3.24879 11.2444 3.06339 11.059C2.87798 10.8736 2.77281 10.6228 2.77053 10.3606C2.76825 10.0984 2.86905 9.84578 3.0512 9.65718L8.3542 4.35418C8.40069 4.30769 8.43757 4.2525 8.46273 4.19176C8.48789 4.13102 8.50084 4.06592 8.50084 4.00018C8.50084 3.93443 8.48789 3.86933 8.46273 3.80859C8.43757 3.74786 8.40069 3.69267 8.3542 3.64618C8.30772 3.59969 8.25253 3.56281 8.19179 3.53766C8.13105 3.5125 8.06595 3.49955 8.0002 3.49955C7.93446 3.49955 7.86936 3.5125 7.80862 3.53766C7.74788 3.56281 7.69269 3.59969 7.6462 3.64618L2.3432 8.95018C2.15218 9.13467 1.99982 9.35536 1.895 9.59937C1.79018 9.84338 1.73501 10.1058 1.7327 10.3714C1.7304 10.6369 1.781 10.9003 1.88156 11.1461C1.98212 11.3919 2.13063 11.6152 2.31841 11.803C2.5062 11.9908 2.7295 12.1393 2.9753 12.2398C3.22109 12.3404 3.48445 12.391 3.75001 12.3887C4.01557 12.3864 4.278 12.3312 4.52201 12.2264C4.76602 12.1216 4.98671 11.9692 5.1712 11.7782L10.4742 6.47518C11.1306 5.81877 11.4994 4.92848 11.4994 4.00018C11.4994 3.07187 11.1306 2.18159 10.4742 1.52518C9.81779 0.868768 8.92751 0.5 7.9992 0.5C7.0709 0.5 6.18061 0.868768 5.5242 1.52518L0.930204 6.12118C0.883713 6.16763 0.846877 6.22283 0.821819 6.28358C0.796761 6.34434 0.782977 6.40946 0.783204 6.47518Z"
                      fill="#808792"
                    />
                  </svg>
                  Attachment
                </div>
                <p className="support_filename">
                  Allowed File Extensions: .jpg, .jpeg, .png, .pdf, .doc, .docx
                </p>
              </div>
              <button className="support_add btn">Add new +</button>
            </div>
            <button type="submit" className="btn btn-green support_submit">
              Order Withdraw
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};
export default SupportTicket;
