import "./history.css";

const History = () => {
  
  return (
    <main className="page page-wrapper">
      <section className="history history_transactions">
        <div className="history__container">
          <div className="history__wrapper">
            <h1 className="history_title user_page__title">History</h1>
            <div className="history_text user_page__text">
              Track your deposit history
            </div>
            <div className="history_controls dis-f aic">
              <label htmlFor="#from">
                <span>All</span>
                <input id="#from" type="date" value="2023-06-05"  />
                <div className="date_icon">
                  <img src="./icons/datapicer.svg" alt="" />
                </div>
              </label>
              <label htmlFor="#to">
                <span>All</span>
                <input id="#to" type="date" value="2023-06-05"  />
                <div className="date_icon">
                  <img src="./icons/datapicer.svg" alt="" />
                </div>
              </label>
              <select name="operation" id="operation">
                <option value="">Type of operation</option>
                <option value="Transactions" selected>
                  Transactions
                </option>
                <option value="Commission">Commission</option>
                <option value="Game log">Game log</option>
              </select>
              <select name="filter" id="filter">
                <option value="">All</option>
                <option value="Transactions">Transactions</option>
                <option value="Commission">Commission</option>
                <option value="Game log">Game log</option>
              </select>
            </div>
            <div className="history_table__wrapper" data-simplebar>
              <div className="history_table">
                <div className="history_table__heading">
                  <div className="history_table_col">Transaction ID</div>
                  <div className="history_table_col">Gateway</div>
                  <div className="history_table_col">Amount</div>
                  <div className="history_table_col">Status</div>
                  <div className="history_table_col">Date</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">JRN8GC19VD4C</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">19BTC</div>
                  <div className="history_table_col red">Decline</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">WK8R3RPQQTA4</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">17.83BTC</div>
                  <div className="history_table_col green">Succesees</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">JRN8GC19VD4C</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">19BTC</div>
                  <div className="history_table_col red">Decline</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">WK8R3RPQQTA4</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">17.83BTC</div>
                  <div className="history_table_col green">Succesees</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">JRN8GC19VD4C</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">19BTC</div>
                  <div className="history_table_col red">Decline</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">WK8R3RPQQTA4</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">17.83BTC</div>
                  <div className="history_table_col green">Succesees</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">JRN8GC19VD4C</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">19BTC</div>
                  <div className="history_table_col red">Decline</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
                <div className="history_table_row">
                  <div className="history_table_col">WK8R3RPQQTA4</div>
                  <div className="history_table_col">Okipays</div>
                  <div className="history_table_col">17.83BTC</div>
                  <div className="history_table_col green">Succesees</div>
                  <div className="history_table_col">2023-05-16 10:36 PM</div>
                </div>
              </div>
            </div>

            <ul className="pagination_list list-reset dis-f jcc aic">
              <li>
                <a href="#" className="disabled pagination_prev">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" className="active">
                  1
                </a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <span>...</span>
              <li>
                <a href="#">99</a>
              </li>
              <li>
                <a href="#" className="pagination_next">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
};
export default History;
