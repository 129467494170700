import "./blog.css";
import { Link } from "react-router-dom";
import BlogTitleIcon from '../../assets/icons/blog_title_img.svg';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/blog", false, /\.(png|jpe?g|svg|webp)$/)
);

const Blog = () => {
  return (
    <main className="page page-wrapper">
      <section className="hero_blog">
        <div className="hero_blog__container">
          <div className="hero_blog__wrapper">
            <img
              loading="lazy"
              src={images['hero_blog.png']}
              alt="blog"
              className="hero_blog__img"
            />
            <div className="hero_blog__content">
              <h1 className="hero_blog__title title_h1">
                <img
                  loading="lazy"
                  src={BlogTitleIcon}
                  alt="Our blog news"
                  aria-hidden="true"
                />
                Our blog news
              </h1>
              <div className="hero_blog__text">
                The latest news about sports, gambling industry and technology
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog_articles">
        <div className="blog_articles__container">
          <div className="blog_articles__items">
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_1.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Digital Pseudonyms: One More Way to Make Working From Home
                Secure
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_2.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Best Steam Summer Sale 2022 PC Game Deals{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link href="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_3.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Space Wars 2 Powerpoints by NetEnt{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_4.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Ethereum Price Indicators Point at Buy Despite Sell-Off After
                Merge{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_1.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Digital Pseudonyms: One More Way to Make Working From Home
                Secure{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_2.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Best Steam Summer Sale 2022 PC Game Deals{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_1.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Digital Pseudonyms: One More Way to Make Working From Home
                Secure
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_2.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Best Steam Summer Sale 2022 PC Game Deals{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_3.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Space Wars 2 Powerpoints by NetEnt{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_4.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Ethereum Price Indicators Point at Buy Despite Sell-Off After
                Merge{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_1.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Digital Pseudonyms: One More Way to Make Working From Home
                Secure{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
            <article className="blog_article">
              <img
                loading="lazy"
                src={images['blog_artcicle_image_2.png']}
                alt="Digital Pseudonyms: One More Way to Make Working From Home Secure"
                className="blog_article__image"
              />
              <h3 className="blog_article__title">
                Best Steam Summer Sale 2022 PC Game Deals{" "}
              </h3>
              <div className="blog_article__bottom dis-f aic">
                <Link to="/" className="blog_article__btn btn btn-pink">
                  Read more
                </Link>
                <time>3 MAR 2023</time>
              </div>
            </article>
          </div>

          <ul className="pagination_list list-reset dis-f jcc aic">
            <li>
              <a to="/" className="disabled pagination_prev">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a to="/" className="active">
                1
              </a>
            </li>
            <li>
              <a to="/">2</a>
            </li>
            <li>
              <a to="/">3</a>
            </li>
            <span>...</span>
            <li>
              <a to="/">99</a>
            </li>
            <li>
              <a to="/" className="pagination_next">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};
export default Blog;
