import "./withdraw.css";
import { useContext, useEffect } from "react";

import { domain } from "../../config";

import AuthContext from "../../context/AuthContext";


const Withdraw = () => {
  const { user } = useContext(AuthContext);

  useEffect( () => {
  }, [user]);
  

  return (
    <main className="page page-wrapper">
      <div className="withdraw_page">
        <div className="withdraw_page__container">
          <div className="withdraw_page__wrapper">
            <div className="withdraw_page__bottom">
              <div className="withdraw_page_title">Withdraw</div>
              <div className="withdraw_page_desrc">
                Payment methods for the region <span>{user == null ? "" : user.country}</span>
              </div>
              <div className="deposit_info">
                <div className="deposit_info__item">
                  ID <span>{user == null ? "" : user.userID}</span>
                </div>
                <div className="deposit_info__item">
                  Current Balance: <span>{user == null ? "0 EURO" : (user.balance + ' ' + user.currency)}</span>
                </div>
              </div>
            </div>

            <form action="#" className="withdraw_form">
              <label>
                Select crypto
                <select name="ctypto" id="crypto">
                  <option value="">Select currency</option>
                  <option value="Bitcoin">Bitcoin (BTC)</option>
                  <option value="Ethereum">Ethereum (ETH)</option>
                  <option value="Tron">TRON (TRX)</option>
                  <option value="Litecoin">Litecoin (LTC)</option>
                  <option value="Tether">Tether (USDT)</option>
                  <option value="Binance Coin">Binance Coin(BNB)</option>
                  <option value="Binance">Binance USD(BUSD)</option>
                  <option value="USD">USD Coin (USDC)</option>
                </select>
              </label>
              <label htmlFor="">
                Enter amount
                <div className="withdraw_page_amount_wrapper">
                  <input type="text" placeholder="0.00"  />
                  <div className="withdraw_page_btn__amout">USD</div>
                </div>
                <p>Min 5$ and Max amount of Money balance</p>
              </label>
              <label htmlFor="">
                Sent to
                <input type="email" placeholder="Enter adress"  />
                <p>Send to (enter address)</p>
              </label>
              <label>
                Send to
                <select name="network" id="network">
                  <option value="">choose network</option>
                  <option value="choose 1">choose network 1</option>
                  <option value="choose 2">choose network 2</option>
                  <option value="choose 3">choose network 3</option>
                </select>
                <p>Note: Transfer fee included</p>
              </label>
              <button type="submit" className="btn btn-green withdraw_page_btn">
                Order Withdraw
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Withdraw;
