import { createContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { domain } from "../config";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Check if the uset loggedIn
  const checkUserLoggedIn = useCallback(async () => {
    try {
      const response = await fetch(`${domain()}/users/myprofile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const result = await response.json();
      console.log("Result: " + result);

      if (!result.error) {
        setLoading(false);
        setIsAuthenticated(true);
        setUser(result);
        setError(null);

        if (
          location.pathname === "/login" ||
          location.pathname === "/register"
        ) {
          navigate("/casino", { replace: true });
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
        setError(result.error);
        console.log(result.error);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    checkUserLoggedIn();
    setLoading(false);
  }, []);

  // Login request
  const loginUser =useCallback( async (userData) => {
    try {
      setLoading(true);
      const response = await fetch(`${domain()}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...userData }),
      });

      const user = await response.json();

      if (!user.error) {
        localStorage.setItem("accessToken", user.accessToken);
        setUser(user.user);
        setIsAuthenticated(true);
        checkUserLoggedIn();
        navigate("/", { replace: true });
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      //setError(user.error);
      console.log(error);
    }
    setLoading(false);
  }, []);

  // Register request
  const registerUser = useCallback( async (userData) => {
    try {
      setLoading(true);
      const response = await fetch(`${domain()}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...userData }),
      });

      const user = await response.json();

      if (!user.error) {
        //navigate("/login", { replace: true });
        localStorage.setItem("accessToken", user.accessToken);
        setIsAuthenticated(true);
        setUser(user.user);
        checkUserLoggedIn();
        navigate("/", { replace: true });
      } else {
        setIsAuthenticated(false);
        setError(user.error);
        console.log(user.error);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const logoutUser = useCallback( async (userData) => {
    try {
      setLoading(true);
      localStorage.removeItem("accessToken");
      setIsAuthenticated(false);
      setUser(null);
      checkUserLoggedIn();
      navigate("/login", { replace: true });
    } catch (error) {
      console.log();
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loginUser,
        registerUser,
        logoutUser,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
