import "./reset_password.css";
import ResetSuccessIcon from "../../assets/icons/reset_success.svg";

const ResetPassword = () => {
  

  return (
    <main className="page page-wrapper">
      <section className="reset reset_commission">
        <div className="reset__container">
          <div className="reset__wrapper ">
            <h1 className="reset_title user_page__title">Reset password</h1>
            <div className="reset_text user_page__text">
              Enter your current password and new password
            </div>
            <form action="#" className="reset_form">
              <label>
                Current password
                <input type="password" placeholder="Current password"  />
              </label>
              <label>
                New Password
                <input type="password" placeholder="Password"  />
              </label>
              <label>
                Confirm Password
                <input type="password" placeholder="Confirm Password"  />
              </label>
              <button className="btn btn-green reset_bnt">
                Change Password
              </button>
            </form>
          </div>
        </div>

        {/* Success Message After Save*/}
        {/*<div className="reset_pop" style={{ "dislpay": "none", "z-index": "50" }}>
                <div className="reset_pop__wrapper">
                    <div className="reset_pop__content">
                        <img src={ResetSuccessIcon} alt="Success" />
                        <div className="reset_pop__title">
                            Success
                        </div>
                        <div className="reset_pop__text">
                            Your password successfully changed
                        </div>
                        <button className="btn btn-green reset_pop__btn">Home</button>
                    </div>
                </div> 
    </div> */}
      </section>
    </main>
  );
};
export default ResetPassword;
