
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/));


const FooterBottom = () => {
    return (
        <div className="footer_bottom dis-f jcsb aic">
          <div className="footer_bottom__copy">
            All Rights Reserved © CrossGaming {new Date().getFullYear()}. Top Online Casino Games
          </div>
          <div className="footer_bottom__crypto dis-f ">
            <img loading="lazy" src={images['crypto_1.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_2.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_3.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_4.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_5.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_6.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_7.png']} alt="crypto" />
            <img loading="lazy" src={images['crypto_8.png']} alt="crypto" />
          </div>
        </div>
    )
}
export default FooterBottom