const HeroTournaments = () => {
  return (
    <section className="hero_tournaments">
      <div className="hero_tournaments__container">
        <div className="hero_tournaments__wrapper">
          <img
            loading="lazy"
            src="./img/tournaments/hero_tournaments.png"
            alt="Tournaments"
            className="hero_tournaments__img"
          />
          <div className="hero_tournaments__content">
            <h1 className="hero_tournaments__title title_h1">
              <img
                loading="lazy"
                src="./icons/touranments.svg"
                alt="Our blog news"
                aria-hidden="true"
              />
              Our Awesome Tournaments
            </h1>
            <div className="hero_tournaments__text">
              Dive in to our wide range of in-house games, live casino and slots
              to experience a thrilling casino adventure.
            </div>
            <a href="#" className="hero_tournaments__btn btn btn-green">
              Register Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeroTournaments;
