import FooterBottom from './FooterBottom';
import FooterCenter from './FooterCenter';
import FooterTop from './FooterTop';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <FooterTop />
        <FooterCenter />
        <FooterBottom />
      </div>
    </footer>
  );
};

export default Footer;
