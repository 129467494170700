import { Link } from "react-router-dom";
import "./referals.css";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/));

const Referals = () => {
  return (
    <main className="page page-wrapper">
      <section className="referals">
        <div className="referals__container">
          <h1 className="referals__title">Refer a Friend</h1>
          <div className="referals__descr">
            <span>
              Refer a friend to register and deposit on Cross Gaming, and earn 300%
              Bonus
            </span>
          </div>
          <div className="referals__link">
            <div className="referals__link__btn">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.59961 11.3968C6.59961 8.67077 6.59961 7.30777 7.44261 6.46077C8.28661 5.61377 9.64361 5.61377 12.3596 5.61377H15.2396C17.9546 5.61377 19.3116 5.61377 20.1556 6.46077C20.9996 7.30777 20.9996 8.67077 20.9996 11.3968V16.2168C20.9996 18.9428 20.9996 20.3058 20.1556 21.1528C19.3126 21.9998 17.9546 21.9998 15.2396 21.9998H12.3596C9.64361 21.9998 8.28661 21.9998 7.44261 21.1528C6.59961 20.3058 6.59961 18.9428 6.59961 16.2168V11.3968Z"
                  fill="#E965E2"
                />
                <path
                  opacity="0.5"
                  d="M4.172 3.172C3 4.343 3 6.229 3 10V12C3 15.771 3 17.657 4.172 18.828C4.789 19.446 5.605 19.738 6.792 19.876C6.6 19.036 6.6 17.88 6.6 16.216V11.397C6.6 8.671 6.6 7.308 7.443 6.461C8.287 5.614 9.644 5.614 12.36 5.614H15.24C16.892 5.614 18.04 5.614 18.878 5.804C18.74 4.611 18.448 3.792 17.828 3.172C16.657 2 14.771 2 11 2C7.229 2 5.343 2 4.172 3.172Z"
                  fill="#E965E2"
                />
              </svg>
            </div>{" "}
            https://crossgaming.com?reference=789654123
          </div>
          <div className="referals_contacts ">
            <div className="referals__items dis-f jcc aic">
              <Link to="#">
                <img src={images['facebook_ref.svg']} alt="Facebook" />
              </Link>
              <Link to="#">
                <img src={images['linkedin_ref.svg']} alt="LinkedIn" />
              </Link>
              <Link to="#">
                <img src={images['mingcute_ref.svg']} alt="Mingcute" />
              </Link>
              <Link to="#">
                <img src={images['instagram_ref.svg']} alt="Instagram" />
              </Link>
            </div>
            <Link to="#" className="referals_btn">
              Invite contacts
            </Link>
          </div>
          <div className="referals_stat dis-f jcc">
            <div className="referals_stat__left">
              <div className="referals_stat__reg">10</div>Registered
            </div>
            <div className="referals_stat__right">
              <div className="referals_stat__suc">16</div>Successful
            </div>
          </div>
          <div className="referals_table">
            <div className="referals_table__heading">
              <div className="referals_table_col">Username</div>
              <div className="referals_table_col">Email</div>
            </div>
            <div className="referals_table_row">
              <div className="referals_table_col">cryptoplayer</div>
              <div className="referals_table_col">player@gmail.com</div>
            </div>
            <div className="referals_table_row">
              <div className="referals_table_col">cryptoplayer</div>
              <div className="referals_table_col">player@gmail.com</div>
            </div>
            <div className="referals_table_row">
              <div className="referals_table_col">cryptoplayer</div>
              <div className="referals_table_col">player@gmail.com</div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Referals;
