import { Link } from "react-router-dom";
import "./single.css";
import SinglePageImg from '../../assets/blog/single_page_img.png';
import SingleContentImage from '../../assets/blog/single_content__image.png';
import SingleRecomendedImg from '../../assets/blog/single_recomended_img.png';

const SingleArticle = () => {
  return (
    <main className="page page-wrapper">
      <section className="hero_single">
        <div className="hero_single__container">
          <div className="hero_single__wrapper">
            <img
              loading="lazy"
              src={SinglePageImg}
              alt="single"
              className="hero_single__img"
            />
          </div>
        </div>
      </section>

      <section className="single">
        <div className="single__container">
          <div className="single__wrapper dis-f">
            <div className="single_left">
              <div className="single_left__top">
                <h1 className="single_title">
                  Digital Pseudonyms: One More Way to Make Working From Home
                  Secure
                </h1>
                <ul className="single_descr list-reset dis-f">
                  <li className="single_descr__date">
                    <time>July 03 , 2023</time>
                  </li>
                  <li className="single_descr__comments">
                    comments : <span>35</span>
                  </li>
                  <li className="single_descr__category">
                    Category : <span>Luxury games</span>
                  </li>
                </ul>
              </div>
              <div className="single_left__content">
                <p>
                  Upon arrival, your senses will be rewarded with the pleasant
                  scent of lemongrass oil used to clean the natural wood found
                  throughout the room, creating a relaxing atmosphere within the
                  space. A wonderful serenity has taken possession of my entire
                  soul, like these sweet mornings of spring which I enjoy with
                  my whole heart. I am alone, and feel the charm of existence in
                  this spot, which was created for the bliss of souls like mine.
                  I am so happy, my dear friend, so absorbed in the exquisite.
                </p>
                <img
                  loading="lazy"
                  src={SingleContentImage}
                  alt=""
                />
                <h3>
                  Not how long, but how well you have lived is the main thing.
                </h3>
                <p>
                  When you are ready to indulge your sense of excitement, check
                  out the range of water- sports opportunities at the resort’s
                  on-site water-sports center. Want to leave your stress on the
                  water? The resort has kayaks, paddleboards, or the low-key
                  pedal boats. Snorkeling equipment is available as well, so you
                  can experience the ever-changing undersea environment.
                </p>
                <p>
                  Not only do visitors to a bed and breakfast get a unique
                  perspective on the place they are visiting, they have options
                  for special packages not available in other hotel settings.
                  Bed and breakfasts can partner easily with local businesses
                  for a smoothly organized and highly personalized vacation
                  experience. The Fife and Drum Inn offers options such as the
                  Historic Triangle Package that includes three nights at the
                  Inn, breakfasts, and admissions to historic Williamsburg,
                  Jamestown, and Yorktown. Bed and breakfasts also lend
                  themselves to romance.
                </p>
                <p>
                  Part of the charm of a bed and breakfast is the uniqueness;
                  art, décor, and food are integrated to create a complete
                  experience. For example, the Fife and Drum retains the
                  colonial feel of the area in all its guest rooms. Special
                  features include antique furnishings, elegant four poster beds
                  in some guest rooms, as well folk art and artifacts from the
                  restoration period of the historic area available for guests
                  to enjoy.
                </p>
              </div>
              <div className="single_nav dis-f aic">
                <a href="#" className="single_nav__btn single_nav__prev disabled">
                  Previous
                </a>
                <a href="#" className="single_nav__btn single_nav__next">
                  Next
                </a>
              </div>
            </div>
            <div className="single_right">
              <div className="single_right__btns dis-f aic">
                <a href="#" className="single_btn">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6992 0.886719C14.918 1.02344 15.0273 1.26953 14.9453 1.51562L13.1953 13.3281C13.168 13.5195 13.0586 13.7109 12.8672 13.793C12.7852 13.8477 12.6758 13.9023 12.5664 13.9023C12.457 13.9023 12.375 13.875 12.293 13.8477L9.61328 12.6992L6.57812 14.668C6.46875 14.7227 6.33203 14.75 6.22266 14.75C6.14062 14.75 6.03125 14.7227 5.92188 14.6953C5.70312 14.5586 5.59375 14.3398 5.59375 14.0938V11.0039L1.38281 9.25391C1.16406 9.14453 1 8.92578 1 8.67969C0.972656 8.43359 1.10938 8.1875 1.32812 8.07812L14.0156 0.859375C14.2344 0.722656 14.5078 0.75 14.6992 0.886719ZM11.0898 4.03125L3.13281 8.54297L5.97656 9.74609L11.0898 4.03125ZM6.87891 12.8906L8.13672 12.0977L6.87891 11.5508V12.8906ZM12.0469 12.2891L13.3594 3.45703L7.23438 10.2656L12.0469 12.2891Z"
                      fill="white"
                      fillOpacity="0.5"
                    />
                  </svg>
                  share
                </a>
                <a href="#" className="single_btn">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9375 0.75H4.0625C3.32422 0.75 2.75 1.35156 2.75 2.0625V13.875C2.75 14.5586 3.46094 14.9688 4.0625 14.6406L8 12.3438L11.9102 14.6406C12.5117 14.9688 13.25 14.5586 13.25 13.875V2.0625C13.25 1.35156 12.6484 0.75 11.9375 0.75ZM11.9375 13.1094L8 10.8125L4.0625 13.1094V2.22656C4.0625 2.14453 4.11719 2.0625 4.19922 2.0625H11.7461C11.8555 2.0625 11.9375 2.14453 11.9375 2.22656V13.1094Z"
                      fill="white"
                      fillOpacity="0.5"
                    />
                  </svg>
                  marking
                </a>
              </div>
              <div className="single_right__tags single_right__item">
                <div className="single_right__title single_title__tag">tags</div>
                <ul className="single_tags__list list-reset">
                  <li>
                    <a href="#">Crypto</a>
                  </li>
                  <li>
                    <a href="#">Gambling</a>
                  </li>
                  <li>
                    <a href="#">Luxury games</a>
                  </li>
                  <li>
                    <a href="#">Bitcoin</a>
                  </li>
                  <li>
                    <a href="#">Binance wallet</a>
                  </li>
                  <li>
                    <a href="#">Crypto Casino</a>
                  </li>
                </ul>
              </div>
              <div className="single_right__item single_right__recomended">
                <div className="single_right__title single_title__recomended">
                  Recomended
                </div>
                <ul className="single_recomended__list list-reset">
                  <li className="dis-f aic">
                    <img
                      loading="lazy"
                      src={SingleRecomendedImg}
                      alt=""
                    />
                    <div className="single_list__content">
                      <div className="single_list__title">
                        How to Spend the Perfect Day on Croatia’s Most Magical
                        Island
                      </div>
                      <a href="#" className="single_list__btn">
                        Read more
                      </a>
                    </div>
                  </li>
                  <li className="dis-f aic">
                    <img
                      loading="lazy"
                      src={SingleRecomendedImg}
                      alt=""
                    />
                    <div className="single_list__content">
                      <div className="single_list__title">
                        How to Spend the Perfect Day on Croatia’s Most Magical
                        Island
                      </div>
                      <a href="#" className="single_list__btn">
                        Read more
                      </a>
                    </div>
                  </li>
                  <li className="dis-f aic">
                    <img
                      loading="lazy"
                      src={SingleRecomendedImg}
                      alt=""
                    />
                    <div className="single_list__content">
                      <div className="single_list__title">
                        How to Spend the Perfect Day on Croatia’s Most Magical
                        Island
                      </div>
                      <a href="#" className="single_list__btn">
                        Read more
                      </a>
                    </div>
                  </li>
                  <li className="dis-f aic">
                    <img
                      loading="lazy"
                      src={SingleRecomendedImg}
                      alt=""
                    />
                    <div className="single_list__content">
                      <div className="single_list__title">
                        How to Spend the Perfect Day on Croatia’s Most Magical
                        Island
                      </div>
                      <a href="#" className="single_list__btn">
                        Read more
                      </a>
                    </div>
                  </li>
                  <li className="dis-f aic">
                    <img
                      loading="lazy"
                      src={SingleRecomendedImg}
                      alt=""
                    />
                    <div className="single_list__content">
                      <div className="single_list__title">
                        How to Spend the Perfect Day on Croatia’s Most Magical
                        Island
                      </div>
                      <a href="#" className="single_list__btn">
                        Read more
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default SingleArticle;
