import { Link } from "react-router-dom";
import HeroSlideTablet from '../../assets/homepage/hero_slide_tablet_1.png';
import HeroSlideMobile from '../../assets/homepage/hero_slide_mobile_1.png';
import HeroSlide from '../../assets/homepage/hero_slide_1.png';


const SwiperSlide = (props) => {
  return (
    <div className="swiper-slide">
      <div className="hero_top__card">
        <picture>
          <source
            media="(max-width: 980px)"
            srcSet={HeroSlideTablet}
          />
          <source
            media="(max-width: 768px)"
            srcSet={HeroSlideMobile}
          />
          <img
            loading="lazy"
            src={HeroSlide}
            alt="slide"
          />
        </picture>

        <div
          className="hero_top__content"
          data-swiper-parallax="-300"
          data-swiper-parallax-duration="600"
        >
          <div className="hero_top__title">
            <span> $10</span> JUST FOR REGISTRATION
          </div>
          <div className="hero_top__descr">
            Dive in to our wide range of in-house games, live casino and slots
            to experience a thrilling casino adventure.
          </div>
          <Link to="#" className="hero_top__btn btn btn-pink">
            GET BONUS
          </Link>
        </div>
      </div>
    </div>
  );
};
export default SwiperSlide;
