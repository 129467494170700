import "./deposit.css";
import PayMethIcon from "../../assets/pay_meth.png";
import { useContext, useEffect, useState } from "react";
import { domain } from "../../config";

import AuthContext from "../../context/AuthContext";


function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/icons", false, /\.(png|jpe?g|svg|webp)$/)
);

const Deposit = () => {
  const { user, isAuthenticated, logoutUser } = useContext(AuthContext);
  let [okipays, setOkipays] = useState(null);
  //const [country, setCountry] = useState('Cyprus');


  const [formValue, setFormValue] = useState({
    amount: "",
    promocode: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formValue.amount) {
      console.log("Enter required fields.");
      return;
    };
    createCheckout(formValue);
  };

  const { amount, promocode } = formValue;

  const createCheckout = (formValue) => {

    /*let now = new Date();
    let oneHourInSeconds = 3600 //* 1000; // 3600 seconds converted to milliseconds
    let oneHourLater = new Date(now.getTime() + oneHourInSeconds);
    let epochOneHourLater = Date.parse(oneHourLater);
    console.log('Time: ' + epochOneHourLater);*/

    // Get the current timestamp in seconds
    let nowInSeconds = Math.floor(Date.now() / 1000);

    // Add 3600 seconds to the current timestamp
    let oneHourLaterInSeconds = nowInSeconds + 7200;

    // Convert back to a Date object if needed
    //let oneHourLaterDate = new Date(oneHourLaterInSeconds * 1000);

    // If you need to use or display the new time
    console.log(oneHourLaterInSeconds);

    
    fetch(`${domain()}payment/blockbee/createcheckout`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json' ,
        "token": localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        "redirect_url": 'https://crossgaming.me/',
        "value": amount,
        "currency": (user == null ? "euro" : user.currency),
        "item_description": `Deposit`,
        "expire_at": oneHourLaterInSeconds,
        "notify_url": `${domain()}`,
        "post": 0
    })
    })
    .then( response => response.json())
    .then( data => window.open(data.data.payment_url, "_blank"))
    //.then( data => console.table(data.data.payment_url))
    .catch( error => console.log(error));
  }


  useEffect( () => {
  }, [user]);

  //https://api.okipays.com/v1/public/currency-network-list
  /*useEffect( () => {
    fetch("https://api.okipays.com/v1/public/currency-network-list")
    .then( response => response.json())
    .then(data => setOkipays(data))
    .catch(error => console.log(error));

    console.table('Okipays' + okipays);
  }, [setOkipays]);*/
  
  return (
    <main className="page page-wrapper">
      <section className="deposit_page">
        <div className="deposit_page__container">
          <div className="deposit_page__wrapper">
            <div className="deposit_page_top">
              <div className="deposit_page_title">Deposit</div>

              <div className="deposit_page__country">
                Payment methods for the region <span>Cyprus</span>
              </div>
              <div className="deposit_page_info">
                <div className="deposit_page_info__item">
                  ID <span>{user == null ? "" : user.userID}</span>
                </div>
                <div className="deposit_page_info__item">
                  Current Balance: <span>{user == null ? "0 EURO" : (user.balance + ' ' + user.currency)}</span>
                </div>
              </div>
            </div>

            <form action="#" onSubmit={handleSubmit} className="deposit_page_form">
              <div className="deposit_page_label "> Select country</div>
              <input id="country" type="text" placeholder="Select country"  />
              <div className="deposit_page_method">
                <div className="deposit_page_label">
                  Deposit via cryptocurrency
                </div>
                <div className="deposit_page_method__top">
                  <img src={PayMethIcon} alt="OKIPAYS" />
                  <div className="deposit_page_method__content">
                    <div className="deposit_page_method__name">OKIPAYS</div>
                    <div className="deposit_page_method__descr">
                      Limit : 0 - 10000 USD, Charge - 0 USD + 0%
                    </div>
                  </div>
                </div>
                <div className="deposit_page_method__bottom">
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_5.png"]} alt="Bitcoin" /> Tether
                    (USDT)
                  </div>

                  {/*<div className="deposit_page_method__item">
                    <img src={images["crypto_2.png"]} alt="Bitcoin" /> Bitcoin
                    (BTC)
                  </div>
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_1.png"]} alt="Bitcoin" /> Ethereum
                    (ETH)
                  </div>
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_3.png"]} alt="Bitcoin" /> TRON
                    (TRX)
                  </div>
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_4.png"]} alt="Bitcoin" /> Litecoin
                    (LTC)
                  </div>
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_6.png"]} alt="Bitcoin" /> Binance
                    Coin(BNB)
                  </div>
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_7.png"]} alt="Bitcoin" /> Binance
                    USD(BUSD)
                  </div>
                  <div className="deposit_page_method__item">
                    <img src={images["crypto_8.png"]} alt="Bitcoin" /> USD Coin
                    (USDC)
                  </div>*/}
                </div>
              </div>
              <div className="deposit_page_label deposit_page_amount__label">
                Enter amount
              </div>
              <div className="deposit_page_amount">
                <input 
                  value={amount} 
                  onChange={handleChange}
                  name="amount"
                  type="text" 
                  placeholder="Enter amount"
                  />
                <div className="deposit_page_promocod">
                  <input 
                    value={promocode} 
                    name="promocode"
                    onChange={handleChange}
                    type="text" 
                    placeholder="Enter promocode"
                    />
                  <div className="promo_btn">Add</div>
                </div>
              </div>
              <div className="deposit_page_amount__items">
                <div className="deposit_page_amount__item">10 usd</div>
                <div className="deposit_page_amount__item">30 usd</div>
                <div className="deposit_page_amount__item">50 usd</div>
                <div className="deposit_page_amount__item">100 usd</div>
              </div>
              <div className="deposit_page_btns">
                <div className="deposit_page_btn__close">Close</div>
                <button className="btn btn-green deposit_page_submit">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Deposit;
