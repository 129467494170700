import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
    let navigate = useNavigate();

    return(
        <main className="page page-wrapper">
        <section className="login_page">
          <div className="login_page__container">
            <div className="login_page__wrapper">
              <div className="login_top">
                <div className="login_title">Your email verification done!.</div>
                <div className="login_text" style={{ fontSize: "14px" }}>
                    Best Crypto Casino. 
                    Biggest crypto crash game. 
                    Provably fair & Live dealer.
                </div>
                {/*<img src={LoginFerIcon} alt="Click to verify" />*/}
                <button 
                  type="button" 
                  className="btn btn-green btn_login"
                  onClick={ () => navigate('/casino')}
                  >
                  Start Playing!
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
}
export default VerifyEmail;