import DataPicer from "../../assets/icons/datapicer.svg";
import CommisionNotFound from "../../assets/icons/commision_not_found.svg";

const Commission = () => {
  
  return (
    <section className="reports reports_commission">
      <div className="reports__container">
        <div className="reports__wrapper">
          <h1 className="reports_title user_page__title">Reports</h1>
          <div className="reports_text user_page__text">Commission</div>
          <div className="reports_controls dis-f aic">
            <label htmlFor="#from">
              <span>From</span>
              <input id="#from" type="date" value="2023-06-05"  />
              <div className="date_icon">
                <img src={DataPicer} alt="data picer" />
              </div>
            </label>
            <label htmlFor="#to">
              <span>To</span>
              <input id="#to" type="date" value="2023-06-05"  />
              <div className="date_icon">
                <img src={DataPicer} alt="data picer" />
              </div>
            </label>
            <select name="operation" id="operation">
              <option value="">Type of operation</option>
              <option value="Transactions">Transactions</option>
              <option value="Commission" selected>
                Commission
              </option>
              <option value="Game log">Game log</option>
            </select>
          </div>
          <div className="reports_table">
            <div className="reports_table__heading">
              <div className="reports_table_col">Commission From</div>
              <div className="reports_table_col">Commission Level</div>
              <div className="reports_table_col">Amount</div>
              <div className="reports_table_col">Title</div>
              <div className="reports_table_col">Transaction</div>
            </div>
            <div className="commission_not_found">
              <img src={CommisionNotFound} alt="Log Not found" />
              <p>Log Not found</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Commission;
