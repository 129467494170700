import { Link } from 'react-router-dom';
//import FooterLogo from '../../assets/footer_logo.png';
import FooterLogo from '../../assets/CrossGaming.png';
import FooterLicenseImage from '../../assets/footer_center_image.png';
import FooterCenterImage from '../../assets/CrossGaming.png';



function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/));


const FooterTop = () => {
  return (
    <div className="footer_top dis-f jcsb">
      <Link to="#" className="footer_logo">
        <img loading="lazy" src={FooterLogo} alt="Cross" />
      </Link>
      <div className="footer_top__left dis-f aic">
        <ul className="footer_socials list-reset dis-f aic">
          <Link to="#">
            <img loading="lazy" src={images['youtube.svg']} alt="youtube" />
          </Link>
          <Link to="#">
            <img loading="lazy" src={images['twitter.svg']} alt="twitter" />
          </Link>
          <Link to="#">
            <img loading="lazy" src={images['facebook.svg']} alt="facebook" />
          </Link>
        </ul>
        <Link to="https://t.me/+9pLMaDdazB80YzQy" target='_blank' className="footer_top__btn btn-blue btn">
          <img loading="lazy" src={images['phone.svg']} alt="phone" />
          Telegram Support
        </Link>
      </div>
      <div className="footer_top__right">
        <img
            loading="lazy"
            src={images['valid-big.png']}
            style={{ height: '30px', wedth: '30px'}}
            alt="valid-big"
            className="footer_defens footer_defens_tablet"
        />
        <img
          loading="lazy"
          src={images['footer_defens.png']}
          alt="defens"
          className="footer_defens footer_defens_tablet"
        />
        <img
          loading="lazy"
          src={images['footer_secure.png']}
          alt="secure"
          className="footer_secure footer_secure_tablet"
        />
        <img
          loading="lazy"
          src={FooterLicenseImage}
          alt="footer"
          className="footer_center__image footer_center__image__tablet"
        />
      </div>
    </div>
  );
};
export default FooterTop;
