import { Link } from 'react-router-dom';
import './promos.css';
import AcceptCryptos from './AcceptCryptos';
import PromotionsIcon from '../../assets/icons/promotions.svg';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  
  const images = importAll(
    require.context("../../assets/promos", false, /\.(png|jpe?g|svg|webp)$/)
  );

const Promo = () => {
  return (
    <main className="page page-wrapper">
      <section className="hero_promos">
        <div className="hero_promos__container">
          <div className="hero_promos__wrapper">
            <img
              loading="lazy"
              src={images['hero_promos.png']}
              alt="Promotions"
              className="hero_promos__img"
            />
            <div className="hero_promos__content">
              <h1 className="hero_promos__title title_h1">
                <img
                  loading="lazy"
                  src={PromotionsIcon}
                  alt="Our blog news"
                  aria-hidden="true"
                />
                Promos and Bonuses
              </h1>
              <div className="hero_promos__text">
                Dive in to our wide range of in-house games, live casino and
                slots to experience a thrilling casino adventure.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="promo_bonuses">
        <div className="promo_bonuses__container">
          <div className="promo_bonuses__wrapper">
            <div className="promo_section__title">
              Permanent bonuses <span>2</span>
            </div>
            <div className="promo_bonuses__items">
              <div className="promo_bonuses__item violet">
                <div className="promo_bonuses__left">
                  <div className="promo_bonuses__end">
                    Promotion will end on 05.12.2023 in 01:00
                  </div>
                  <div className="promo_bonuses__title">
                    Jackpot up to <br />
                    €2500 in Squid Game
                  </div>
                  <div className="promo_bonuses__btns">
                    <a href="#" className="promo_bonuses__btn">
                      Play Now
                    </a>
                    <a href="#" className="promo_bonuses__view">
                      View Details
                    </a>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={images['promo_bonuses__1.png']}
                  alt=""
                  className="promo_bonuses__right"
                />
              </div>
              <div className="promo_bonuses__item pink">
                <div className="promo_bonuses__left">
                  <div className="promo_bonuses__end">
                    Promotion will end on 05.12.2023 in 01:00
                  </div>
                  <div className="promo_bonuses__title">
                    Jackpot up to <br />
                    €2500 in Squid Game
                  </div>
                  <div className="promo_bonuses__btns">
                    <a href="#" className="promo_bonuses__btn">
                      Play Now
                    </a>
                    <a href="#" className="promo_bonuses__view">
                      View Details
                    </a>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={images['promo_bonuses__2.png']}
                  alt=""
                  className="promo_bonuses__right"
                />
              </div>
            </div>
          </div>
          <div className="promo_bonuses__wrapper">
            <div className="promo_section__title">
              Offers and promotions <span>4</span>
            </div>
            <div className="promo_bonuses__items">
              <div className="promo_bonuses__item pink">
                <div className="promo_bonuses__left">
                  <div className="promo_bonuses__end">
                    Promotion will end on 05.12.2023 in 01:00
                  </div>
                  <div className="promo_bonuses__title">
                    Jackpot up to <br />
                    €2500 in Squid Game
                  </div>
                  <div className="promo_bonuses__btns">
                    <a href="#" className="promo_bonuses__btn">
                      Play Now
                    </a>
                    <a href="#" className="promo_bonuses__view">
                      View Details
                    </a>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={images['promo_bonuses__3.png']}
                  alt=""
                  className="promo_bonuses__right"
                />
              </div>
              <div className="promo_bonuses__item orange">
                <div className="promo_bonuses__left">
                  <div className="promo_bonuses__end">
                    Promotion will end on 05.12.2023 in 01:00
                  </div>
                  <div className="promo_bonuses__title">
                    Jackpot up to <br />
                    €2500 in Squid Game
                  </div>
                  <div className="promo_bonuses__btns">
                    <a href="#" className="promo_bonuses__btn">
                      Play Now
                    </a>
                    <a href="#" className="promo_bonuses__view">
                      View Details
                    </a>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={images['promo_bonuses__4.png']}
                  alt=""
                  className="promo_bonuses__right"
                />
              </div>
              <div className="promo_bonuses__item pink">
                <div className="promo_bonuses__left">
                  <div className="promo_bonuses__end">
                    Promotion will end on 05.12.2023 in 01:00
                  </div>
                  <div className="promo_bonuses__title">
                    Jackpot up to <br />
                    €2500 in Squid Game
                  </div>
                  <div className="promo_bonuses__btns">
                    <a href="#" className="promo_bonuses__btn">
                      Play Now
                    </a>
                    <a href="#" className="promo_bonuses__view">
                      View Details
                    </a>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={images['promo_bonuses__2.png']}
                  alt=""
                  className="promo_bonuses__right"
                />
              </div>
              <div className="promo_bonuses__item violet">
                <div className="promo_bonuses__left">
                  <div className="promo_bonuses__end">
                    Promotion will end on 05.12.2023 in 01:00
                  </div>
                  <div className="promo_bonuses__title">
                    Jackpot up to <br />
                    €2500 in Squid Game
                  </div>
                  <div className="promo_bonuses__btns">
                    <a href="#" className="promo_bonuses__btn">
                      Play Now
                    </a>
                    <a href="#" className="promo_bonuses__view">
                      View Details
                    </a>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={images['promo_bonuses__1.png']}
                  alt=""
                  className="promo_bonuses__right"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <AcceptCryptos />
    </main>
  );
};

export default Promo;
