import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FooterSecure from "../../assets/icons/footer_secure.png";
//import FooterLogo from '../../assets/footer_logo.png';
import FooterLogo from "../../assets/CrossGaming.png";
import FooterDefens from "../../assets//icons/footer_defens.png";
import FooterLicenseImage from '../../assets/footer_center_image.png';
import FooterCenterImage from "../../assets/CrossGaming.png";

const FooterCenter = () => {
  const { t } = useTranslation();

  return (
    <div className="footer_center ">
      <div className="footer_center__top dis-f jcsb">
        <div className="footer_menu dis-f">
          <ul className="footer_center__items list-reset">
            <li>{t("footerMenu.about-us-title")}</li>
            <li>
              <Link to="#">{t("footerMenu.about")}</Link>
            </li>
            <li>
              <Link to="#">{t("footerMenu.why-to-choose")}</Link>
            </li>
            <li>
              <Link to="#">{t("footerMenu.referral-program")}</Link>
            </li>
            <li>
              <Link to="#">{t("footerMenu.bonuses")}</Link>
            </li>
            <li>
              <Link to="terms-and-conditions">
              {t("footerMenu.terms-and-conditions")}
              </Link>
            </li>
            <li>
              <Link to="privacy-policy">
                {t("footerMenu.privacy-policy")}
              </Link>
            </li>
            <li>
              <Link to="responsible-gaming">
                {t("footerMenu.resposible-gaming")}
              </Link>
            </li>
            <li>
              <Link to="https://cnews.ng/" target="blank">
                {t("footerMenu.affiliate")}
              </Link>
            </li>
          </ul>
          <ul className="footer_center__items list-reset">
            <li>
              {t("footerMenu.help-title")}
            </li>
            <li>
              <Link to="faq">
                {t("footerMenu.faq")}
              </Link>
            </li>
            <li>
              <Link to="aml-kyc-policy">
              {t("footerMenu.AML-KYC-Policy")}
              </Link>
            </li>
            <li>
              <Link to="refund-policy">
              {t("footerMenu.refund-policy")}
              </Link>
            </li>
          </ul>
          <ul className="footer_center__items list-reset">
            <li>
            {t("footerMenu.our-products-title")}
            </li>
            <li>
              <Link to="#">
              {t("footerMenu.Extra bonuses")}
              </Link>
            </li>
            <li>
              <Link to="casino">
                {t("footerMenu.games")}
              </Link>
            </li>
            <li>
              <Link to="live-casino">
              {t("footerMenu.live-casino")}
              </Link>
            </li>
            <li>
              <Link to="tvbet">
              {t("footerMenu.tvbet")}
              </Link>
            </li>
            <li>
              <Link to="#">
                {t("footerMenu.lottery")}
              </Link>
            </li>
            <li>
              <Link to="fast-games">
                {t("footerMenu.fast-games")}
              </Link>
            </li>
          </ul>
          <ul className="footer_center__items list-reset">
            <li>
              {t("footerMenu.contact-us-title")}
            </li>
            <li>
              <Link to="support">
                {t("footerMenu.contact")}
              </Link>
            </li>
            {/*<li>
              <Link to="#">
              {t("footerMenu.live-chat")}
              </Link>
            </li>*/}
            <li>
              <Link to="support-ticket">
                {t("footerMenu.support-ticket")}
              </Link>
            </li>
            <li>
              <Link to="https://t.me/+9pLMaDdazB80YzQy" target="_blank">
                {t("footerMenu.telegram-link")}
              </Link>
            </li>
          </ul>
        </div>
        <img
          loading="lazy"
          src={FooterLicenseImage}
          alt="Footer"
          className="footer_center__image"
        />
      </div>
      <Link to="#" className="footer_logo_tablet">
        <img loading="lazy" src={FooterLogo} alt="Cross" />
      </Link>

      <div className="footer_center__bottom dis-f aic">
      {/*<img
            loading="lazy"
            src={images['valid-big.png']}
            style={{ height: '30px', wedth: '30px'}}
            alt="valid-big"
            className="footer_defens footer_defens_tablet"
        />*/}

        <img
          loading="lazy"
          src={FooterDefens}
          alt="defens"
          className="footer_defens"
        />
        <img
          loading="lazy"
          src={FooterSecure}
          alt="secure"
          className="footer_secure"
        />
        <div className="footer_attention">
          Attention! It is gambling advertising. Gambling is not suitable for
          solving financial problems. Please read terms and conditions and play
          responsibly.
        </div>
      </div>
      <div className="footer_rules">
        Cross Gaming is owned and operated by Santeda International B.V.
        (registration number: 151296; registered in Curacao) operating through
        its payment agent SANTEDA INTERNATIONAL LIMITED(registration number:
        HE406761; registered in Cyprus with actual address: Patrikiou Loumoumpa,
        7, Block A, Flat A13, 7560 Pervolia, Larnaca, Cyprus).Santeda
        International B.V. is a company registered and established under the
        laws of Curacao and licensed and regulated by the Government of Curaçao
        under the gaming license No. 1668/JAZ issued to Curaçao eGaming,
        authorized and regulated by the Government of Curacao with registered
        address: Pareraweg 45, Curacao.
      </div>
    </div>
  );
};
export default FooterCenter;
