
const Transactions = () => {
  

  return (
    <section className="reports reports_transactions">
      <div className="reports__container">
        <div className="reports__wrapper">
          <h1 className="reports_title user_page__title">Reports</h1>
          <div className="reports_text user_page__text">Transactions</div>
          <div className="reports_controls dis-f aic">
            <label htmlFor="#from">
              <span>From</span>
              <input id="#from" type="date" value="2023-06-05"  />
              <div className="date_icon">
                <img src="./icons/datapicer.svg" alt="" />
              </div>
            </label>
            <label htmlFor="#to">
              <span>To</span>
              <input id="#to" type="date" value="2023-06-05"  />
              <div className="date_icon">
                <img src="./icons/datapicer.svg" alt="" />
              </div>
            </label>
            <select name="operation" id="operation">
              <option value="">Type of operation</option>
              <option value="Transactions" selected>
                Transactions
              </option>
              <option value="Commission">Commission</option>
              <option value="Game log">Game log</option>
            </select>
          </div>
          <div className="reports_table__wrapper" data-simplebar>
            <div className="reports_table">
              <div className="reports_table__heading">
                <div className="reports_table_col">Transaction ID</div>
                <div className="reports_table_col">Amount</div>
                <div className="reports_table_col">Remaining balance</div>
                <div className="reports_table_col">Details</div>
                <div className="reports_table_col">Date</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col red">- 0.2 USD</div>
                <div className="reports_table_col">0.13 USD</div>
                <div className="reports_table_col">Invest to GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:36 PM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col green">+ 0.06 USD</div>
                <div className="reports_table_col">0.33 USD</div>
                <div className="reports_table_col">Win of GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:27 AM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col red">- 0.2 USD</div>
                <div className="reports_table_col">0.13 USD</div>
                <div className="reports_table_col">Invest to GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:36 PM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col green">+ 0.06 USD</div>
                <div className="reports_table_col">0.33 USD</div>
                <div className="reports_table_col">Win of GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:27 AM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col red">- 0.2 USD</div>
                <div className="reports_table_col">0.13 USD</div>
                <div className="reports_table_col">Invest to GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:36 PM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col green">+ 0.06 USD</div>
                <div className="reports_table_col">0.33 USD</div>
                <div className="reports_table_col">Win of GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:27 AM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col red">- 0.2 USD</div>
                <div className="reports_table_col">0.13 USD</div>
                <div className="reports_table_col">Invest to GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:36 PM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col green">+ 0.06 USD</div>
                <div className="reports_table_col">0.33 USD</div>
                <div className="reports_table_col">Win of GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:27 AM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col red">- 0.2 USD</div>
                <div className="reports_table_col">0.13 USD</div>
                <div className="reports_table_col">Invest to GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:36 PM</div>
              </div>
              <div className="reports_table_row">
                <div className="reports_table_col">
                  a79a692c3382e1c0750cfac30
                </div>
                <div className="reports_table_col green">+ 0.06 USD</div>
                <div className="reports_table_col">0.33 USD</div>
                <div className="reports_table_col">Win of GoldKing</div>
                <div className="reports_table_col">2023-05-16 10:27 AM</div>
              </div>
            </div>
          </div>

          <ul className="pagination_list list-reset dis-f jcc aic">
            <li>
              <a href="#" className="disabled pagination_prev">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="#" className="active">
                1
              </a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <span>...</span>
            <li>
              <a href="#">99</a>
            </li>
            <li>
              <a href="#" className="pagination_next">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Transactions;
