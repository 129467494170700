import { Link } from "react-router-dom";
import "./support.css";
import CommisionNotFound from '../../assets/icons/commision_not_found.svg';

const Support = () => {
  return (
    <main className="page page-wrapper">
      <section className="support support_commission">
        <div className="support__container">
          <div className="support__wrapper ">
            <div className="support_content dis-f jcsb aifs">
              <div className="support_left">
                <h1 className="support_title user_page__title">Support</h1>
                <div className="support_text user_page__text">Tickets</div>
              </div>
              <button className="btn support_btn">+ Open ticket</button>
            </div>
            <div className="support_table__wrapper" data-simplebar>
              <div className="support_table">
                <div className="support_table__heading">
                  <div className="support_table_col">Subject</div>
                  <div className="support_table_col">Status</div>
                  <div className="support_table_col">Priority</div>
                  <div className="support_table_col">Last reply</div>
                  <div className="support_table_col">Action</div>
                </div>
                <div className="commission_not_found">
                  <img
                    src={CommisionNotFound}
                    alt="Log Not found"
                  />
                  <p>Log Not found</p>
                </div>
              </div>
            </div>
          </div>

          <ul className="pagination_list list-reset dis-f jcc aic">
            <li>
              <Link to="#" className="disabled pagination_prev">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="#" className="active">
                1
              </Link>
            </li>
            <li>
              <Link to="#">2</Link>
            </li>
            <li>
              <Link to="#">3</Link>
            </li>
            <span>...</span>
            <li>
              <Link to="#">99</Link>
            </li>
            <li>
              <Link to="#" className="pagination_next">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};
export default Support;
