import './two_factor.css';
import IconeCopy from '../../assets/two_factor/icone_copy.svg';
import QRCode from '../../assets/two_factor/qrcode.png';

const TwoFactorAuth = () => {
  
  return (
    <main className="page page-wrapper">
      <section className="two_factor reset_commission">
        <div className="two_factor__container">
          <div className="two_factor__wrapper ">
            <h1 className="two_factor_title user_page__title">
              Setup 2-Factor Authenticator
            </h1>
            <div className="two_factor_text user_page__text">
              Scan the image below with the two-factor authentication app. If
              don't have an scan application{" "}
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">
                DOWNLOAD APP
              </a>
              . if you can't use a enter this text code instead.
            </div>

            <div className="two_factor__bottom">
              <div className="two_factor__code">
                <img src={QRCode} alt="QRCODE" />
              </div>
              <div className="two_factor__contetn">
                <div className="two_factor_copy">
                  <div className="two_copy__btn">
                    <img
                      src={IconeCopy}
                      alt="image"
                      aria-hidden="true"
                    />
                  </div>
                  <span>2F6TAXLOFPUX5YAU</span>
                </div>
                <button className="btn two_factor__btn btn-green">
                  Enable two factor autenticator
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="two_pop">
          <div className="two_pop__wrapper">
            <div className="two_pop__content">
              <div className="two_pop__title">VERIFY YOUR OTP</div>
              <form action="#" className="two_pop__form">
                <input
                  type="text"
                  placeholder="Enter Google Authenticator Code"
                  
                />
                <div className="two_pop__btns dis-f aic jcsb">
                  <button className="btn two_pop__close">Close</button>
                  <button className="btn two_pop__verify btn-green">
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>*/}
      </section>
    </main>
  );
};
export default TwoFactorAuth;
