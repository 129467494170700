import { Link } from "react-router-dom";
import "./live_casino.css";

import LivecasImage from "../../assets/homepage/livecas_image.png";
import CardsIcons from "../../assets/icons/cards.png";
import LiveHero from "../../assets/casino/live_hero.jpg";
import LiveHeroTablet from "../../assets/casino/live_hero_tablet.jpg";
import AcceptCryptos from "./AcceptCryptos";

const LiveCasino = () => {
  return (
    <main className="page page-wrapper">
      <section className="live">
        <div className="live__container">
          <div className="live_wrapper">

            <div className="live_hero">
              <picture>
                <source media="(max-width: 980px)" srcSet={LiveHeroTablet} />
                <img src={LiveHero} alt="Live" />
              </picture>
              <div className="live_content">
                <div className="live_title title_h1">
                  <img loading="lazy" src={CardsIcons} alt="Casino" />
                  Live Casino
                </div>
                <div className="live_subtitle">Providers</div>
              </div>
            </div>

            <div className="live_filter">
              <Link to="/" className="live_filter__item">
                All
              </Link>
              <div className="live_filter__slider swiper swiper-initialized swiper-horizontal swiper-pointer-events">
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-f3aaef731a444e62"
                  aria-live="polite"
                >
                  <div
                    className="swiper-slide swiper-slide-active"
                    role="group"
                    aria-label="1 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="/" className="live_filter__item">
                      Live Casino
                    </Link>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-next"
                    role="group"
                    aria-label="2 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="/" className="live_filter__item active">
                      Live Casino 1
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="3 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="/" className="live_filter__item">
                      Live Casino 2
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="4 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 3
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="5 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 4
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="6 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 5
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="7 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link href="#" className="live_filter__item">
                      Live Casino 6
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="8 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 7
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="9 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 8
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="10 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 9
                    </Link>
                  </div>
                  <div
                    className="swiper-slide"
                    role="group"
                    aria-label="11 / 11"
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    <Link to="#" className="live_filter__item">
                      Live Casino 10
                    </Link>
                  </div>
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span>
              </div>
              <div className="live_filte__nav">
                <div
                  className="live_filte__arrow live_filte__arrow-prev swiper-button-disabled"
                  tabIndex="-1"
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-f3aaef731a444e62"
                  aria-disabled="true"
                >
                  <svg
                    width="37"
                    height="32"
                    viewBox="0 0 37 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.8776 16C22.8776 16.1778 22.8409 16.3502 22.7673 16.5173C22.6938 16.6845 22.5958 16.8231 22.4733 16.9333L15.7091 23.0667C15.4395 23.3111 15.0964 23.4333 14.6798 23.4333C14.2632 23.4333 13.9201 23.3111 13.6505 23.0667C13.3809 22.8222 13.2461 22.5111 13.2461 22.1333C13.2461 21.7556 13.3809 21.4445 13.6505 21.2L19.3853 16L13.6505 10.8C13.3809 10.5556 13.2461 10.2445 13.2461 9.86668C13.2461 9.48891 13.3809 9.1778 13.6505 8.93335C13.9201 8.68891 14.2632 8.56668 14.6798 8.56668C15.0964 8.56668 15.4395 8.68891 15.7091 8.93335L22.4733 15.0667C22.6203 15.2 22.7247 15.3445 22.7865 15.5C22.8482 15.6556 22.8786 15.8222 22.8776 16Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <div
                  className="live_filte__arrow live_filte__arrow-next"
                  tabIndex="0"
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-f3aaef731a444e62"
                  aria-disabled="false"
                >
                  <svg
                    width="37"
                    height="32"
                    viewBox="0 0 37 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.8776 16C22.8776 16.1778 22.8409 16.3502 22.7673 16.5173C22.6938 16.6845 22.5958 16.8231 22.4733 16.9333L15.7091 23.0667C15.4395 23.3111 15.0964 23.4333 14.6798 23.4333C14.2632 23.4333 13.9201 23.3111 13.6505 23.0667C13.3809 22.8222 13.2461 22.5111 13.2461 22.1333C13.2461 21.7556 13.3809 21.4445 13.6505 21.2L19.3853 16L13.6505 10.8C13.3809 10.5556 13.2461 10.2445 13.2461 9.86668C13.2461 9.48891 13.3809 9.1778 13.6505 8.93335C13.9201 8.68891 14.2632 8.56668 14.6798 8.56668C15.0964 8.56668 15.4395 8.68891 15.7091 8.93335L22.4733 15.0667C22.6203 15.2 22.7247 15.3445 22.7865 15.5C22.8482 15.6556 22.8786 15.8222 22.8776 16Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div className="dashboard__bottom">
              <div className="live_subtitle">Games</div>
              <div className="slots_items__login">
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
                <Link to="#" className="slots_item__login">
                  <img src={LivecasImage} alt="Slot Name" />
                </Link>
              </div>
              <Link to="#" className="btn btn-green dashboard__btn">
                More games
              </Link>
            </div>
            
          </div>
        </div>
      </section>

      <AcceptCryptos />
    </main>
  );
};
export default LiveCasino;
